import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerGrid, Loader } from "../../../components";
import { useAppDispatch } from "../../../hooks";
import { showAlert } from "../../../store/slices/alertSnackbarSlice";
import { MESSAGES as msg, ROUTE_PATHS } from "../../../utils/constants";

declare global {
  interface Window {
    gigya: any;
  }
}

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleGigyaReady = () => {
      console.log("Gigya Service Ready");
      window.gigya.accounts.showScreenSet({
        screenSet: "COV_CUPS-RegistrationLogin",
        startScreen: "cups-reset-password-screen",
        containerID: "resetPasswordScreenGigyaContainer",
        onBeforeScreenLoad: () => setIsLoading(false),
        onAfterSubmit: (event: any) => {
          console.log("Form submitted, checking status...", event);
          if (event.response && event.response.errorCode === 0) {
            dispatch(showAlert({ message: msg.PASSWORD_RESET_SUCCESS, alertType: "success", duration: 1000 }));
            setTimeout(() => {
              navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
            }, 1100);
          } else if (event.response && event.response.errorCode !== 0) {
            dispatch(showAlert({ message: event.response.errorMessage, alertType: "error", duration: 1000 }));
          }
        },
      });
    };
    handleGigyaReady();
  }, [navigate]);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="resetPasswordPageContainer">
        <ContainerGrid>
          <Grid size={12}>
            <Box id="resetPasswordScreenGigyaContainer" />
          </Grid>
        </ContainerGrid>
      </Box>
    </>
  );
};

export default ResetPassword;
