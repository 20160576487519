import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import SellerData, { ApiResponse } from "./types";

const sellerApi = {
  getSeller(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<SellerData>>(API.SELLER, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateSeller(payload: SellerData) {
    const { id, distributor_name, country_name, state_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<SellerData>>(`${API.SELLER}/${id}`, payloadWithoutId);
  },
  createSeller(payload: SellerData) {
    return axios.post<ApiResponse<SellerData>>(API.SELLER, payload);
  },
  getSellerForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<SellerData>>(API.SELLER, { params });
  },
};

export default sellerApi;
