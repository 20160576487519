import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import RequestData, { ApiResponse } from "./types";

const requestApi = {
  getRequest(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<RequestData>>(API.REQUEST, { params, headers: { noLoader: "true" } });
    // return axios.get<ApiResponse<RequestData>>(
    //   "request?search=%7B%22id%22:%221725544916092%22,%22business_entity_id%22:%221%22%7D&sort=[%22requested_date%22,%22desc%22]&limit=10&offset=0"
    // );
  },
  getRequestById(payload: Filter) {
    const params = generateParams(payload);
    return axios.get<ApiResponse<RequestData>>(API.REQUEST, { params });
  },
  updateRequest(payload: RequestData) {
    const { id, country_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<RequestData>>(`${API.REQUEST}/${id}`, payloadWithoutId);
  },
  createRequest(payload: RequestData) {
    return axios.post<ApiResponse<RequestData>>(API.REQUEST, payload);
  },
};

export default requestApi;
