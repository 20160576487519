import { useState, useEffect, useRef } from "react";
import { AddOrEditDistributorModal, CommonPageLayout } from "../../../components";
import { distributorApi } from "../../../api";
import { DistributorData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";

const columns: MRT_ColumnDef<DistributorData>[] = [
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}`, size: 300 },
  { accessorKey: "sales_orgnisation", header: LABELS.SALES_ORGANISATION },
  { accessorKey: "sold_to_number", header: LABELS.SOLD_NUMBER },
  { accessorKey: "entity_name", header: LABELS.BUSINESS_ENTITY, size: 270 },
  createBooleanColumn<DistributorData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const Distributor = () => {
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState<DistributorData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { filters } = useAppSelector((state) => state.dataTable);
  const [filtersReset, setFiltersReset] = useState(false);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) fetchData();
  }, [filters, filtersReset]);

  useEffect(() => {
    if (isInitialRender.current) isInitialRender.current = false;
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await distributorApi.getDistributor(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: DistributorData) => {
    try {
      if (formData.id) await distributorApi.updateDistributor(formData);
      else await distributorApi.createDistributor(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Distributor"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditDistributorModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default Distributor;
