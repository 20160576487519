import { useState, useEffect, useRef } from "react";
import { AddOrEditStateModal, CommonPageLayout } from "../../../components";
import { stateApi } from "../../../api";
import { StateData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { createBooleanColumn } from "../../../utils/helpers";
import { LABELS } from "../../../utils/constants";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";

const columns: MRT_ColumnDef<StateData>[] = [
  { accessorKey: "name", header: LABELS.STATE },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  createBooleanColumn<StateData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const State = () => {
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState<StateData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { filters } = useAppSelector((state) => state.dataTable);
  const [filtersReset, setFiltersReset] = useState(false);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) fetchData();
  }, [filters, filtersReset]);

  useEffect(() => {
    if (isInitialRender.current) isInitialRender.current = false;
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await stateApi.getState(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: StateData) => {
    try {
      if (formData.id) await stateApi.updateState(formData);
      else await stateApi.createState(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <>
      <CommonPageLayout
        title="State"
        dataTable={dataTable}
        totalRows={totalRows}
        columns={columns}
        AddOrEditModal={AddOrEditStateModal}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default State;
