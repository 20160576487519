import { useState, useCallback, useMemo, useEffect } from "react";
import { axios } from "../api/axiosInstance";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { showAlert } from "../store/slices/alertSnackbarSlice";
import { hideLoader, showLoader } from "../store/slices/dataTableSlice";
import { MESSAGES as msg } from "../utils/constants";

const useAxiosLoader = () => {
  const { userDetails } = useAppSelector((state) => state.user);
  const [counter, setCounter] = useState(0);
  const dispatch = useAppDispatch();

  const token = userDetails.token;

  const updateLoader = useCallback(
    (isIncrement: boolean, config: any) => {
      const isNoLoader = Boolean(config?.headers?.noLoader);
      if (isNoLoader) {
        if (isIncrement) {
          dispatch(showLoader());
        } else {
          dispatch(hideLoader());
        }
      } else {
        setCounter((counter) => counter + (isIncrement ? 1 : -1));
      }
    },
    [dispatch]
  );

  const interceptors = useMemo(
    () => ({
      request: (config: any) => {
        updateLoader(true, config);
        if (token) config.headers.Authorization = `Token ${token}`;
        return config;
      },
      response: (response: any) => {
        updateLoader(false, response.config);
        const successMessage = response?.data?.message;
        successMessage && dispatch(showAlert({ message: successMessage, alertType: "success" }));
        return response;
      },
      error: (error: any) => {
        updateLoader(false, error.config);
        const errorMessage = error.response?.data?.message || msg.ERROR_PLS_TRY_AGAIN;
        console.error("API Error: ", errorMessage);
        dispatch(showAlert({ message: errorMessage, alertType: "error" }));
        return Promise.reject(error);
      },
    }),
    [updateLoader, dispatch, token]
  );

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(interceptors.request, interceptors.error);
    const responseInterceptor = axios.interceptors.response.use(interceptors.response, interceptors.error);

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};

export default useAxiosLoader;
