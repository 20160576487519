import InternalUser from "./InternalUser/InternalUser";
import ExternalUser from "./ExternalUser/ExternalUser";
import { useAppSelector } from "../../hooks";
import { USER_ROLES } from "../../utils/constants";

const Requests = () => {
  const { userDetails } = useAppSelector((state) => state.user);

  return <>{userDetails.role === USER_ROLES.INTERNAL ? <InternalUser /> : <ExternalUser />}</>;
};

export default Requests;
