import { LABELS } from "../../../../utils/constants";

export const FIELD_NAMES = {
  STATUS: "status",
  REQUESTED_BY: "requested_by",
  REQUESTED_DATE: "requested_date",
  REQUESTED_ID: "id",
  DISTRIBUTOR_ID: "distributor_id",
  DISTRIBUTOR_LOCATION_ID: "distributor_location_id",
  UNIT_OF_MEASUREMENT: "unit_of_measurement",
  DISTRIBUTOR_SELLER_FIRST_NAME: "distributor_seller_first_name",
  DISTRIBUTOR_SELLER_LAST_NAME: "distributor_seller_last_name",
  DISTRIBUTOR_CUSTOMER_ID: "distributor_customer_id",
  DISTRIBUTOR_CUSTOMER: "distributor_customer",
  CUSTOMER_NUMBER: "customer_number",
  COUNTRY_ID: "country_id",
  STATE_ID: "state_id",
  CITY: "city",
  SELLER_ID: "seller_id",
  MARKET_ID: "market_id",
  APPLICATION: "application",
  OEM_ID: "oem_id",
  OEM_NAME: "oem_name",
  THIRD_PARTY: "third_party",
  COMPETITOR_ID: "competitor_id",
  COMPETITOR_NAME: "competitor_name",
  COMPETITOR_VOLUME: "competitor_volume",
  SALES_TEXT: "sales_text",
  PRODUCT_ID: "product_id",
  PRODUCT_NAME: "product_name",
  DISTRIBUTOR_PRODUCT_NUMBER: "distributor_product_number",
  VALID_FROM: "valid_from",
  VALID_TO: "valid_to",
  MINIMUM_QUANTITY: "minimum_quantity",
  LIST_PRICE: "list_price",
  LIST_DISCOUNT: "list_discount",
  REQUESTED_PRICE: "requested_price",
  REQUESTED_DISCOUNT: "requested_discount",
  INTO_STOCK_PRICE: "into_stock_price",
  SUPPORT_PRICE: "support_price",
  ADJUSTMENT: "adjustment",
  COMMENT: "comment",
} as const;

export const BUTTON_INITIAL_VALUES = {
  accountMarketing: LABELS.EXISTING,
  oem: LABELS.EXISTING,
  competitor: LABELS.EXISTING,
};
