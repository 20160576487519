import Grid from "@mui/material/Grid2";
import { useState, useEffect, useRef } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { LABELS, MESSAGES as msg, ROUTE_PATHS } from "../../../utils/constants";
import { DataTable, PageTitle, ContainerGrid, AddOrUpdateSavedFiltersModal, Loader } from "../../../components";
import { RequestData, SavedFilterData } from "../../../api/type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestApi, savedFilterApi } from "../../../api";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";
import {
  formatDateColumnsInFilters,
  getCurrentDateISO,
  getFullName,
  getNext60DaysISO,
  getPrevious60DaysISO,
  hasFiltersChanged,
} from "../../../utils/helpers";
import { DEFAULT_COLUMNS, ONLY_DATE_COLUMNS, initialColumnFilter, initialSortingFilter } from "../contant";
import { showModal, MODAL_ACTION_ADD } from "../../../store/slices/modalSlice";
import { RootState } from "../../../store";
import { showAlert } from "../../../store/slices/alertSnackbarSlice";
import dayjs from "dayjs";

interface Categories {
  id: string;
  name: string;
}

const categories: Categories[] = [
  { id: "1", name: "Submitted" },
  { id: "2", name: "Approved" },
  { id: "3", name: "Expiration in next 60 days" },
  { id: "4", name: "Expired in last 60 days" },
];

const ExternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInitialRender = useRef(true);

  const { filters } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [activeCategory, setActiveCategory] = useState<string>(categories[0].id);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtersReset, setFiltersReset] = useState<boolean>(false);
  const [savedFiltersDropdown, setSavedFiltersDropdown] = useState<SavedFilterData[]>([]);
  const [isSavedFiltersDropdownLoading, setIsSavedFiltersDropdownLoading] = useState<boolean>(false);
  const [hasFetchedSavedFilter, setHasFetchedSavedFilter] = useState<boolean>(false);
  const [activeSavedFilter, setActiveSavedFilter] = useState<string>("");

  const handleTableRowClick = (rowData: any) => navigateToRequestDetail(rowData.id, LABELS.VIEW);

  const checkActionType = (event: { type: string; data: any }) => {
    if (event.type === LABELS.CLONE) {
      navigateToRequestDetail(event.data.id, LABELS.CLONE);
    }
  };

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const applySelectedSavedFilter = (event: SelectChangeEvent) => {
    const selectedFilterId = event.target.value;
    setActiveSavedFilter(selectedFilterId);
    const selectedFilter = savedFiltersDropdown.find((filter) => filter.id === Number(selectedFilterId));
    if (selectedFilter) {
      const filterColumns = formatDateColumnsInFilters(selectedFilter.filter.savedColumns);
      dispatch(resetOrAddFilters({ column: filterColumns }));
    } else {
      dispatch(resetOrAddFilters({ sorting: initialSortingFilter }));
    }
  };

  const loadSavedFiltersOnDropdownOpen = () => {
    if (!hasFetchedSavedFilter) {
      setIsSavedFiltersDropdownLoading(true);
      fetchSavedFilters();
    }
  };

  const saveFilterData = async (formData: { id?: number; filter_name?: string }) => {
    // Check if there are any filters to save
    if (!filters.column.length || !hasFiltersChanged(filters.column)) {
      dispatch(showAlert({ message: msg.NO_FILTER_TO_SAVE, alertType: "warning" }));
      return;
    }

    const savedColumns = filters.column;
    const userEmail = userDetails.email;

    const payload = {
      filter: { oem_layout: true, savedColumns: savedColumns },
      user_email: userEmail,
    };

    try {
      if (formData.id) {
        await savedFilterApi.updateSavedFilter({ ...payload, id: formData.id });
      } else if (formData.filter_name) {
        await savedFilterApi.createSavedFilter({
          ...payload,
          filter_name: formData.filter_name,
        });
      }
      setHasFetchedSavedFilter(false);
    } catch (err) {
      console.error("Failed to save filters:", err);
    }
  };

  const fetchSavedFilters = async () => {
    try {
      const { data } = await savedFilterApi.getSavedFilter(userDetails.email);
      setSavedFiltersDropdown(data.data);
      setHasFetchedSavedFilter(true);
      setIsSavedFiltersDropdownLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchRequestData = async () => {
    try {
      const categoryFilters = getCategoryFilters();
      const filterToAdd = categoryFilters[activeCategory as keyof typeof categoryFilters];
      const updatedFilters = getUpdatedFilters(filterToAdd);
      updateColumnsBasedOnCategory();
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
      isInitialRender.current = false;
    } catch (err) {
      console.error("Failed to fetch data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCategoryFilters = () => ({
    "1": { id: "status", value: [LABELS.SUBMITTED] },
    "2": { id: "status", value: [LABELS.APPROVED] },
    "3": [
      { id: "valid_to_range", value: { start: getCurrentDateISO(), end: getNext60DaysISO() } },
      { id: "status", value: [LABELS.SUBMITTED, LABELS.APPROVED] },
    ],
    "4": [
      { id: "valid_to_range", value: { start: getPrevious60DaysISO(), end: getCurrentDateISO() } },
      { id: "status", value: [LABELS.SUBMITTED, LABELS.APPROVED] },
    ],
  });

  const getUpdatedFilters = (filterToAdd: any) => {
    if (!filterToAdd) return filters;
    const userFilter = { id: "requested_by", value: getFullName(userDetails) };
    return {
      ...filters,
      column: Array.isArray(filterToAdd)
        ? [...filters.column, ...filterToAdd, userFilter]
        : [...filters.column, userFilter, filterToAdd],
      user_type: "external",
    };
  };

  const updateColumnsBasedOnCategory = () => {
    if (["3", "4"].includes(activeCategory)) {
      setColumns(DEFAULT_COLUMNS);
    } else {
      const filteredColumns = DEFAULT_COLUMNS.filter(
        (col) => col.accessorKey !== "valid_from" && col.accessorKey !== "valid_to"
      );
      const validFromIndex = DEFAULT_COLUMNS.findIndex((col) => col.accessorKey === "valid_from");
      filteredColumns.splice(validFromIndex, 0, ...ONLY_DATE_COLUMNS);
      setColumns(filteredColumns);
    }
  };

  useEffect(() => {
    dispatch(resetOrAddFilters({ sorting: initialSortingFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) {
      fetchRequestData();
    }
  }, [filters, filtersReset]);

  useEffect(() => {
    if (!isInitialRender.current) {
      setIsLoading(true);
      fetchRequestData();
    }
    isInitialRender.current = false;
  }, [activeCategory]);

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={activeCategory}
                label="Select Category"
                onChange={(e) => setActiveCategory(e.target.value)}>
                {categories.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ mr: 2, padding: "15px" }}
                onClick={() => dispatch(showModal({ type: MODAL_ACTION_ADD }))}>
                Save Filters
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 160 }}>
                <InputLabel>Apply Saved Filter</InputLabel>
                <Select
                  value={activeSavedFilter}
                  label="Apply Saved Filter"
                  onChange={applySelectedSavedFilter}
                  onOpen={loadSavedFiltersOnDropdownOpen}>
                  {isSavedFiltersDropdownLoading ? (
                    <MenuItem disabled>
                      <em>Loading...</em>
                    </MenuItem>
                  ) : (
                    [
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...savedFiltersDropdown.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.filter_name}
                        </MenuItem>
                      )),
                    ]
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </ContainerGrid>
      {!isLoading && (
        <ContainerGrid>
          <Grid size={12}>
            <DataTable
              data={dataTable}
              totalRows={totalRows}
              columns={columns}
              exportFileName="Request"
              actionButton={{ clone: true, edit: false, view: true }}
              handleActionButton={checkActionType}
              handleTableRowClick={handleTableRowClick}
            />
          </Grid>
        </ContainerGrid>
      )}
      {isLoading && <Loader />}
      <AddOrUpdateSavedFiltersModal handleSubmit={saveFilterData} />
    </Box>
  );
};

export default ExternalUser;
