import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import OemData, { ApiResponse } from "./types";

const oemApi = {
  getOem(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<OemData>>(API.OEM, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateOem(payload: OemData) {
    const { id, distributor_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<OemData>>(`${API.OEM}/${id}`, payloadWithoutId);
  },
  createOem(payload: OemData) {
    return axios.post<ApiResponse<OemData>>(API.OEM, payload);
  },
  getOemForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<OemData>>(API.OEM, { params });
  },
};

export default oemApi;
