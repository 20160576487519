import { useState, useEffect, useRef } from "react";
import { AddOrEditDistributorContactModal, CommonPageLayout } from "../../../components";
import { distributorContactApi } from "../../../api";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { DistributorContactData } from "../../../api/type";
import { LABELS, USER_ROLES } from "../../../utils/constants";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";

const columns: MRT_ColumnDef<DistributorContactData>[] = [
  { accessorFn: (row) => `${row.first_name} ${row.last_name}`, id: "name", header: LABELS.FULL_NAME },
  { accessorKey: "email", header: LABELS.EMAIL },
  {
    accessorFn: (row) => row.distributor_data.length,
    id: "connected_distributors",
    header: LABELS.CONNECTED_DISTRIBUTORS,
    enableColumnFilter: false,
    enableSorting: false,
  },
  createBooleanColumn<DistributorContactData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const DistributorContact = () => {
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState<DistributorContactData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { filters } = useAppSelector((state) => state.dataTable);
  const { userDetails } = useAppSelector((state) => state.user);
  const [filtersReset, setFiltersReset] = useState(false);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) fetchData();
  }, [filters, filtersReset]);

  useEffect(() => {
    if (isInitialRender.current) isInitialRender.current = false;
  }, []);

  const fetchData = async () => {
    try {
      let updatedFilters = filters;
      if (userDetails.role === USER_ROLES.EXTERNAL) {
        updatedFilters = {
          ...filters,
          user_type: "external",
          email: userDetails.email,
          // email: "usman@test.com",
        };
      }
      const { data } = await distributorContactApi.getDistributorContact(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: DistributorContactData) => {
    try {
      if (formData.id) await distributorContactApi.updateDistributorContact(formData);
      else await distributorContactApi.createDistributorContact(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Distributor Contact"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditDistributorContactModal}
      handleSubmit={handleSubmit}
      isShowNewBtn={userDetails.role === USER_ROLES.INTERNAL}
      actionButton={{ edit: userDetails.role === USER_ROLES.INTERNAL, view: userDetails.role === USER_ROLES.EXTERNAL }}
    />
  );
};

export default DistributorContact;
