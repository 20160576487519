import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { AlertMessage, CommonAutocomplete, CommonCheckboxField, CommonTextField, ContainerGrid } from "../index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideModal, resetModalState } from "../../store/slices/modalSlice";
import { LABELS, MESSAGES as msg } from "../../utils/constants";
import { checkForChanges, getModalTitle, isModalEditMode, setFormikValues } from "../../utils/helpers";
import "./Modals.scss";

interface Props {
  handleSubmit: (formData: any) => void;
}

const validationSchema = yup.object({
  distributor_name: yup.string().label(`${LABELS.DISTRIBUTOR} ${LABELS.NAME}`).required(),
  sold_to_number: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.SOLD_NUMBER}`)
    .label(LABELS.SOLD_NUMBER)
    .required(),
  sales_orgnisation: yup.string().label(LABELS.SALES_ORGANISATION).required(),
  business_entity_id: yup.string().label(LABELS.BUSINESS_ENTITY).required(),
  is_obsolete: yup.boolean().label(LABELS.OBSOLETE),
});

const AddOrEditDistributorModal = ({ handleSubmit }: Props) => {
  const { isVisible, modalType, modalData } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      distributor_name: "",
      sold_to_number: null,
      sales_orgnisation: "",
      business_entity_id: null,
      is_obsolete: false,
    },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      const { isChanged, mergedData } = checkForChanges(formData, modalData, dispatch);
      if (isChanged) {
        handleSubmit(mergedData);
        dispatch(hideModal());
        dispatch(resetModalState());
      }
    },
  });

  useEffect(() => {
    if (isModalEditMode(modalType)) setFormikValues(formik, modalData);
  }, [isVisible, modalData]);

  const handleModalExited = () => {
    formik.resetForm();
    dispatch(resetModalState());
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="sm"
      open={isVisible}
      scroll="body"
      onClose={() => dispatch(hideModal())}
      TransitionProps={{ onExited: handleModalExited }}
      className="cmnModalWrapper addOrEditDistributorModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{getModalTitle("Distributor", modalType)}</DialogTitle>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <AlertMessage show={isModalEditMode(modalType)} />
            <Grid size={12}>
              <CommonTextField
                name="distributor_name"
                label={`${LABELS.DISTRIBUTOR} ${LABELS.NAME}*`}
                formikProps={{
                  field: formik.getFieldProps("distributor_name"),
                  meta: formik.getFieldMeta("distributor_name"),
                }}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                name="sold_to_number"
                label={`${LABELS.SOLD_NUMBER}*`}
                formikProps={{
                  field: formik.getFieldProps("sold_to_number"),
                  meta: formik.getFieldMeta("sold_to_number"),
                }}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                name="sales_orgnisation"
                label={`${LABELS.SALES_ORGANISATION}*`}
                formikProps={{
                  field: formik.getFieldProps("sales_orgnisation"),
                  meta: formik.getFieldMeta("sales_orgnisation"),
                }}
              />
            </Grid>
            <Grid size={12}>
              <CommonAutocomplete
                label={`${LABELS.CHOOSE} ${LABELS.BUSINESS_ENTITY}*`}
                value={formik.values.business_entity_id}
                onChange={(value) => formik.setFieldValue("business_entity_id", value)}
                apiType="businessEntity"
                optionLabelKey="entity_name"
                error={formik.touched.business_entity_id && Boolean(formik.errors.business_entity_id)}
                helperText={formik.touched.business_entity_id && formik.errors.business_entity_id}
              />
            </Grid>
            {isModalEditMode(modalType) && (
              <Grid size={12}>
                <CommonCheckboxField
                  name="is_obsolete"
                  label={`${LABELS.OBSOLETE}`}
                  formikProps={{ field: formik.getFieldProps("is_obsolete"), meta: formik.getFieldMeta("is_obsolete") }}
                />
              </Grid>
            )}
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" className="cancelBtn" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button>
          <Button variant="contained" color="info" type="submit" className="submitBtn">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrEditDistributorModal;
