import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import * as yup from "yup";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { CommonTextField, ContainerGrid } from "../../../components";
import { setFormikValues } from "../../../utils/helpers";
import { LABELS, MESSAGES as msg } from "../../../utils/constants";
import { useAppSelector } from "../../../hooks";

const validationSchema = yup.object({
  first_name: yup.string().label(LABELS.FIRST_NAME).required(),
  last_name: yup.string().label(LABELS.LAST_NAME).required(),
  email: yup.string().label(LABELS.EMAIL).email(msg.VALID_EMAIL).required(),
});

const InternalUser = () => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });
  const { userDetails } = useAppSelector((state) => state.user);

  useEffect(() => {
    setFormikValues(formik, userDetails);
  }, []);

  return (
    <Box className="myAccountPageContainer internal">
      <ContainerGrid>
        <Grid size={12}>
          <Box className="pageTitleWrapper">
            <h1 className="heading">MyAccount details</h1>
          </Box>
        </Grid>
        <Grid size={12}>
          <Box className="profileFormWrapper">
            <form>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name="first_name"
                    label={`${LABELS.FIRST_NAME}`}
                    formikProps={{
                      field: formik.getFieldProps("first_name"),
                      meta: formik.getFieldMeta("first_name"),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name="last_name"
                    label={`${LABELS.LAST_NAME}`}
                    formikProps={{
                      field: formik.getFieldProps("last_name"),
                      meta: formik.getFieldMeta("last_name"),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name="email"
                    label={`${LABELS.EMAIL}`}
                    formikProps={{
                      field: formik.getFieldProps("email"),
                      meta: formik.getFieldMeta("email"),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </form>
          </Box>
        </Grid>
      </ContainerGrid>
    </Box>
  );
};

export default InternalUser;
