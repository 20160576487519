import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowData,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";
import { Edit, FileDownload, FileCopy, Visibility } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { MODAL_ACTION_EDIT, MODAL_ACTION_VIEW, showModal } from "../../store/slices/modalSlice";
import { generateFileName } from "../../utils/helpers";
import { setFilters } from "../../store/slices/dataTableSlice";
import * as XLSX from "xlsx";
import { LABELS } from "../../utils/constants";
import "./DataTable.scss";

interface CommonTableProps<T extends MRT_RowData> {
  data: T[];
  totalRows: number;
  columns: MRT_ColumnDef<T>[];
  exportFileName: string;
  actionButton?: {
    clone?: boolean;
    edit?: boolean;
    view?: boolean;
  };
  handleActionButton?: (x: any) => void;
  handleTableRowClick?: (x: any) => void;
}

const DataTable = <T extends MRT_RowData>({
  data,
  totalRows,
  columns,
  exportFileName,
  actionButton = {
    clone: false,
    edit: true,
    view: false,
  },
  handleActionButton,
  handleTableRowClick,
}: CommonTableProps<T>) => {
  const dispatch = useAppDispatch();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { filters, isRefetching } = useAppSelector((state) => state.dataTable);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(filters.column);
  const [sorting, setSorting] = useState<MRT_SortingState>(filters.sorting);
  const [pagination, setPagination] = useState<MRT_PaginationState>(filters.pagination);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (filters.column.length > 0 || filters.sorting.length > 0) {
      setColumnFilters(filters.column);
      setSorting(filters.sorting);
      setPagination(filters.pagination);
    }
  }, [filters]);

  useEffect(() => {
    const payload = {
      column: columnFilters,
      pagination,
      sorting,
    };
    dispatch(setFilters(payload));
  }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((row) => {
        return columns.reduce((acc, col) => {
          acc[col.accessorKey] = row[col.accessorKey as keyof T];
          return acc;
        }, {} as any);
      })
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const fileName = generateFileName(exportFileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    positionToolbarAlertBanner: "bottom",
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: totalRows,
    state: {
      columnFilters,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleTableRowClick && handleTableRowClick(row.original),
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap" }}>
        <Button onClick={handleExportToExcel} startIcon={<FileDownload />}>
          Export
        </Button>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        actionButton.clone && (
          <MenuItem
            key={0}
            onClick={() => {
              handleActionButton && handleActionButton({ type: LABELS.CLONE, data: row.original });
            }}
            sx={{ m: 0, color: "#4cbcec" }}>
            <ListItemIcon>
              <FileCopy sx={{ color: "#4cbcec" }} />
            </ListItemIcon>
            Clone
          </MenuItem>
        ),
        actionButton.edit && (
          <MenuItem
            key={1}
            onClick={() => {
              dispatch(showModal({ type: MODAL_ACTION_EDIT, data: row.original }));
              closeMenu();
            }}
            sx={{ m: 0, color: "#2e7d32" }}>
            <ListItemIcon>
              <Edit sx={{ color: "#2e7d32" }} />
            </ListItemIcon>
            Edit
          </MenuItem>
        ),
        actionButton.view && (
          <MenuItem
            key={2}
            onClick={() => {
              dispatch(showModal({ type: MODAL_ACTION_VIEW, data: row.original }));
              closeMenu();
            }}
            sx={{ m: 0, color: "#1976d2" }}>
            <ListItemIcon>
              <Visibility sx={{ color: "#1976d2" }} />
            </ListItemIcon>
            View
          </MenuItem>
        ),
      ].filter(Boolean);
    },
    muiTablePaperProps: () => ({ className: "DataTableComponentContainer" }),
  });

  return <MaterialReactTable table={table} />;
};

export default DataTable;
