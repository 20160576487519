import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import DistributorData, { ApiResponse } from "./types";

const distributorApi = {
  getDistributor(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<DistributorData>>(API.DISTRIBUTOR, { params, headers: { noLoader: "true" } });
  },
  updateDistributor(payload: DistributorData) {
    const { id, entity_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<DistributorData>>(`${API.DISTRIBUTOR}/${id}`, payloadWithoutId);
  },
  createDistributor(payload: DistributorData) {
    return axios.post<ApiResponse<DistributorData>>(API.DISTRIBUTOR, payload);
  },
  getDistributorForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<DistributorData>>(API.DISTRIBUTOR, { params });
  },
};

export default distributorApi;
