import dayjs from "dayjs";
import * as yup from "yup";
import { LABELS, MESSAGES as msg } from "../../../../utils/constants";
import { FIELD_NAMES } from "./constants";

export const baseValidationSchema = yup.object({
  [FIELD_NAMES.STATUS]: yup.string().label(LABELS.STATUS),
  [FIELD_NAMES.REQUESTED_BY]: yup.string().label(LABELS.REQUESTED_BY).required(),
  [FIELD_NAMES.REQUESTED_DATE]: yup.string().label(LABELS.REQUESTED_DATE).required(),
  [FIELD_NAMES.REQUESTED_ID]: yup.string().label(LABELS.REQUESTED_ID),
  [FIELD_NAMES.DISTRIBUTOR_ID]: yup.number().label(LABELS.DISTRIBUTOR).required(),
  [FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]: yup.number().label(`${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}`).required(),
  [FIELD_NAMES.UNIT_OF_MEASUREMENT]: yup.string().label(LABELS.UNIT_OF_MEASUREMENT).required(),
  [FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME]: yup.string().label(LABELS.FIRST_NAME).required(),
  [FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME]: yup.string().label(LABELS.LAST_NAME).required(),
  [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: yup.number().label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}`).required(),
  [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: yup
    .string()
    .label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}`)
    .required(),
  [FIELD_NAMES.CUSTOMER_NUMBER]: yup.string().label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`),
  [FIELD_NAMES.COUNTRY_ID]: yup.number().label(LABELS.COUNTRY).required(),
  [FIELD_NAMES.STATE_ID]: yup.number().label(LABELS.STATE).required(),
  [FIELD_NAMES.CITY]: yup.string().label(LABELS.CITY).required(),
  [FIELD_NAMES.SELLER_ID]: yup.number().label(`${LABELS.COVESTRO_SELLER}`).required(),
  [FIELD_NAMES.MARKET_ID]: yup.number().label(LABELS.MARKET_NAME).required(),
  [FIELD_NAMES.APPLICATION]: yup.string().label(LABELS.APPLICATION).required(),
  [FIELD_NAMES.OEM_ID]: yup.number().label(LABELS.OEM_NAME).required(),
  [FIELD_NAMES.OEM_NAME]: yup.string().label(LABELS.OEM_NAME).required(),
  [FIELD_NAMES.THIRD_PARTY]: yup.boolean().label(LABELS.THIRD_PARTY_CONTRACT),
  [FIELD_NAMES.COMPETITOR_ID]: yup.number().label(LABELS.COMPETITOR_NAME).required(),
  [FIELD_NAMES.COMPETITOR_NAME]: yup.string().label(LABELS.COMPETITOR_NAME).required(),
  [FIELD_NAMES.COMPETITOR_VOLUME]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.COMPETITOR_VOLUME_YEAR}`)
    .label(LABELS.COMPETITOR_VOLUME_YEAR),
  [FIELD_NAMES.SALES_TEXT]: yup.string().label(LABELS.PRODUCT_SALES_TEXT).required(),
  [FIELD_NAMES.PRODUCT_ID]: yup.string().label(LABELS.COVESTRO_PRODUCT_NUMBER).required(),
  [FIELD_NAMES.PRODUCT_NAME]: yup.string().label(LABELS.COVESTRO_PRODUCT).required(),
  [FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER]: yup.string().label(LABELS.DISTRIBUTOR_PRODUCT_NUMBER),
  [FIELD_NAMES.VALID_FROM]: yup.date().label(LABELS.EFFECTIVE_DATE).required(),
  [FIELD_NAMES.VALID_TO]: yup
    .date()
    .label(LABELS.EXPIRATION_DATE)
    .required("Valid to date is required")
    .test("is-greater", msg.EXPIRATION_DATE_IS_LESS, function (value) {
      const { valid_from } = this.parent;
      return value && dayjs(value).isAfter(dayjs(valid_from));
    }),
  [FIELD_NAMES.MINIMUM_QUANTITY]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.MINIMUM_QTY_PURCHASE}`)
    .integer(`The ${LABELS.MINIMUM_QTY_PURCHASE} must be a whole number without decimal places.`)
    .positive(`The ${LABELS.MINIMUM_QTY_PURCHASE} must be a positive number.`)
    .label(LABELS.MINIMUM_QTY_PURCHASE)
    .required(),
  [FIELD_NAMES.LIST_PRICE]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.LIST_PRICE}`)
    .label(LABELS.LIST_PRICE)
    .required(),
  [FIELD_NAMES.LIST_DISCOUNT]: yup.number().label(LABELS.DISCOUNT).required(),
  [FIELD_NAMES.REQUESTED_PRICE]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.REQUESTED}`)
    .label(LABELS.REQUESTED)
    .required(),
  [FIELD_NAMES.REQUESTED_DISCOUNT]: yup.number().label(LABELS.DISCOUNT).required(),
  [FIELD_NAMES.INTO_STOCK_PRICE]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.INTO_STOCK_PRICE}`)
    .label(LABELS.INTO_STOCK_PRICE)
    .required(),
  [FIELD_NAMES.SUPPORT_PRICE]: yup
    .number()
    .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.SUPPORT_PRICE}`)
    .label(LABELS.SUPPORT_PRICE)
    .required(),
  [FIELD_NAMES.ADJUSTMENT]: yup.number().label(LABELS.ADJUSTMENT).required(),
  [FIELD_NAMES.COMMENT]: yup.string().label(LABELS.COMMENT),
});
