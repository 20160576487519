import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./slices/modalSlice";
import alertSnackbarReducer from "./slices/alertSnackbarSlice";
import dataTableReducer from "./slices/dataTableSlice";
import userReducer from "./slices/userSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    alertSnackbar: alertSnackbarReducer,
    dataTable: dataTableReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];

export default store;

// https://redux.js.org/usage/usage-with-typescript?_sm_au_=iVVdV5VnQVs2mhHVBGV0QKs1TRR4F
