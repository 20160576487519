import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import CountryData, { ApiResponse } from "./types";

const countryApi = {
  getCountry(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<CountryData>>(API.COUNTRY, { params, headers: { noLoader: "true" } });
  },
  updateCountry(payload: CountryData) {
    const { id, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<CountryData>>(`${API.COUNTRY}/${id}`, payloadWithoutId);
  },
  createCountry(payload: CountryData) {
    return axios.post<ApiResponse<CountryData>>(API.COUNTRY, payload);
  },
  getCountryForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<CountryData>>(API.COUNTRY, { params });
  },
};

export default countryApi;
