import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { ContainerGrid } from "../index";
import "./Footer.scss";

const Footer = () => {
  return (
    <Box className="footerComponentContainer">
      <ContainerGrid>
        <Grid size={12}>
          <Box className="confidentialTextWrapper">
            <p className="txt">confidential</p>
          </Box>
        </Grid>
      </ContainerGrid>
    </Box>
  );
};

export default Footer;
