import { FC } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { DataTable, PageTitle, ContainerGrid } from "../../components";
import { useAppDispatch } from "../../hooks";
import { showModal, MODAL_ACTION_ADD, MODAL_ACTION_VIEW, MODAL_ACTION_EDIT } from "../../store/slices/modalSlice";
import { generatePageClass } from "../../utils/helpers";

interface AddOrEditModalProps {
  handleSubmit: (formData: any) => void;
}

interface Props {
  title: string;
  dataTable: Array<any>;
  totalRows: number;
  columns: any;
  AddOrEditModal: FC<AddOrEditModalProps>;
  handleSubmit: (formData: any) => void;
  isShowNewBtn?: boolean;
  actionButton?: {
    clone?: boolean;
    edit?: boolean;
    view?: boolean;
  };
}

const CommonPageLayout = ({
  title,
  dataTable,
  totalRows,
  columns,
  AddOrEditModal,
  handleSubmit,
  isShowNewBtn = true,
  actionButton = {
    clone: false,
    edit: true,
    view: false,
  },
}: Props) => {
  const dispatch = useAppDispatch();

  const handleTableRowClick = (rowData: any) => {
    if (actionButton.edit) {
      dispatch(showModal({ type: MODAL_ACTION_EDIT, data: rowData }));
    } else if (actionButton.view) {
      dispatch(showModal({ type: MODAL_ACTION_VIEW, data: rowData }));
    }
  };

  return (
    <Box className={generatePageClass(title)}>
      <PageTitle
        title={title}
        handleBtnNew={() => dispatch(showModal({ type: MODAL_ACTION_ADD }))}
        isShowNewBtn={isShowNewBtn}
      />
      <ContainerGrid>
        <Grid size={12}>
          <DataTable
            data={dataTable}
            totalRows={totalRows}
            columns={columns}
            exportFileName={title}
            handleTableRowClick={handleTableRowClick}
            actionButton={{
              clone: actionButton.clone,
              edit: actionButton.edit,
              view: actionButton.view,
            }}
          />
        </Grid>
      </ContainerGrid>
      <AddOrEditModal handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CommonPageLayout;
