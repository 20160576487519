import { useState, useEffect, useRef } from "react";
import { AddOrEditCovestroSellerModal, CommonPageLayout } from "../../../components";
import { sellerApi } from "../../../api";
import { SellerData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";

const columns: MRT_ColumnDef<SellerData>[] = [
  { accessorKey: "seller_name", header: `${LABELS.COVESTRO_SELLER} ${LABELS.NAME}` },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  { accessorKey: "state_name", header: LABELS.STATE },
  createBooleanColumn<SellerData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const CovestroSeller = () => {
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState<SellerData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { filters } = useAppSelector((state) => state.dataTable);
  const [filtersReset, setFiltersReset] = useState(false);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) fetchData();
  }, [filters, filtersReset]);

  useEffect(() => {
    if (isInitialRender.current) isInitialRender.current = false;
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await sellerApi.getSeller(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: SellerData) => {
    try {
      if (formData.id) await sellerApi.updateSeller(formData);
      else await sellerApi.createSeller(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Covestro Seller"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditCovestroSellerModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default CovestroSeller;
