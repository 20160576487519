import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertSnackbarState {
  isVisible: boolean;
  message: string;
  alertType?: "success" | "info" | "warning" | "error"; // Type of the alert
  duration?: number; // Duration to display the snackbar
}

interface ShowAlertPayload {
  message: string;
  alertType?: "success" | "info" | "warning" | "error";
  duration?: number;
}

const initialState: AlertSnackbarState = {
  isVisible: false,
  message: "",
  alertType: "success",
  duration: 4000,
};

const alertSnackbarSlice = createSlice({
  name: "alertSnackbar",
  initialState,
  reducers: {
    showAlert: (state, action: PayloadAction<ShowAlertPayload>) => {
      const { message, alertType, duration } = action.payload;
      state.isVisible = true;
      state.message = message;
      if (alertType) state.alertType = alertType;
      if (duration) state.duration = duration;
    },
    hideAlert: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showAlert, hideAlert } = alertSnackbarSlice.actions;

export default alertSnackbarSlice.reducer;
