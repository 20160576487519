export const USER_DETAILS = "user_details";
export const ROLE = "role";
export const EXTERNAL = "external";
export const INTERNAL = "internal";
export const LOCALHOST = "localhost";

type Environment = "qa" | "prod";
type Role = typeof INTERNAL | typeof EXTERNAL | typeof LOCALHOST;

interface Link {
  urls: {
    [key in Environment]: {
      domain: string;
      api: string;
      sso?: string;
    };
  };
  role: Role;
}

const links: Link[] = [
  {
    urls: {
      qa: {
        domain: "https://cups-qa.covestro.com",
        api: "https://api.cups-qa.covestro.com/cups/",
      },
      prod: {
        domain: "https://cups-prod.covestro.com",
        api: "https://api.cups-prod.covestro.com/cups/",
      },
    },
    role: EXTERNAL,
  },
  {
    urls: {
      qa: {
        domain: "https://cups.533267023544.aws.glpoly.net",
        api: "https://cups.api.533267023544.aws.glpoly.net/cups/",
        sso: "https://cups.533267023544.aws.glpoly.net/idresponse",
      },
      prod: {
        domain: "https://cups.637423580987.aws.glpoly.net",
        api: "https://cups.api.637423580987.aws.glpoly.net/cups/",
        sso: "https://cups.637423580987.aws.glpoly.net/idresponse",
      },
    },
    role: INTERNAL,
  },
];

export const getRoleByCurrentUrl = (): Role => {
  const currentDomain = window.location.origin;
  for (const link of links) {
    for (const env of Object.keys(link.urls) as Environment[]) {
      if (currentDomain.includes(link.urls[env].domain)) {
        return link.role;
      }
    }
  }
  return LOCALHOST;
};

export const getApiBaseUrlByCurrentDomain = (): string => {
  const currentDomain = window.location.origin;
  for (const link of links) {
    for (const env of Object.keys(link.urls) as Environment[]) {
      if (currentDomain.includes(link.urls[env].domain)) {
        return link.urls[env].api;
      }
    }
  }
  // For localhost development: if accessing internal users, add the internal API endpoint here;
  // if accessing external users, add the external API endpoint.
  const defautlApiBaseUrl = "https://cups.api.533267023544.aws.glpoly.net/cups/";
  return defautlApiBaseUrl;
};

export const getSsoUrlByCurrentDomain = () => {
  const currentDomain = window.location.origin;
  for (const link of links) {
    for (const env of Object.keys(link.urls) as Environment[]) {
      if (currentDomain.includes(link.urls[env].domain)) {
        return link.urls[env].sso;
      }
    }
  }
  const defautlSsoBaseUrl = "http://localhost:3000/idresponse"; // Default Localhost URL otherwise change as per your need.
  return defautlSsoBaseUrl;
};
