import { Filter } from "../../store/slices/dataTableSlice";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateSearchUrlParams } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import DistributorContactData, { ApiResponse } from "./types";

const distributorContactApi = {
  getDistributorContact(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<DistributorContactData>>(API.DISTRIBUTOR_CONTACT, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateDistributorContact(payload: DistributorContactData) {
    const { id, distributor_data, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<DistributorContactData>>(`${API.DISTRIBUTOR_CONTACT}/${id}`, payloadWithoutId);
  },
  createDistributorContact(payload: DistributorContactData) {
    return axios.post<ApiResponse<DistributorContactData>>(API.DISTRIBUTOR_CONTACT, payload);
  },
  getDistributorByEmail(searchParams: { id: string; value: string }[]) {
    const params = generateSearchUrlParams(searchParams);
    return axios.get<ApiResponse<DistributorContactData>>(API.DISTRIBUTOR_CONTACT, { params });
  },
};

export default distributorContactApi;
