import { MRT_ColumnDef } from "material-react-table";
import { Filter } from "../../store/slices/dataTableSlice";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { LABELS, MESSAGES as msg } from "../constants";
import { showAlert } from "../../store/slices/alertSnackbarSlice";
import { MODAL_ACTION_ADD, MODAL_ACTION_EDIT, MODAL_ACTION_VIEW } from "../../store/slices/modalSlice";
import dayjs from "dayjs";

// Generate a file name with a timestamp and a prefix
export const generateFileName = (prefix: string): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const date = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${prefix.toLowerCase()}_${date}_${month}_${year}_${hours}_${minutes}_${seconds}`;
};

// Generate a page class name from a string
export const generatePageClass = (str: string): string =>
  `${str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
    index === 0 ? match.toLowerCase() : match.trim().toUpperCase()
  )}PageContainer`;

type RowData = {
  [key: string]: any;
};
// Create a boolean column definition for Material React Table
export const createBooleanColumn = <T extends { [key in keyof T]: any }>(
  accessorKey: any,
  header: string
  // size: number
): MRT_ColumnDef<T> => {
  return {
    enableSorting: false,
    accessorKey,
    header,
    // size,
    Cell: ({ cell }) => (cell.getValue<boolean>() ? "Yes" : "No"),
    Filter: ({ column }) => {
      const filterValue = (column.getFilterValue() as string) || "";
      return (
        <Box className="dataTableSelectOptWrapper">
          <FormControl fullWidth>
            <Select
              value={filterValue}
              onChange={(e) => column.setFilterValue(e.target.value || undefined)}
              displayEmpty
              MenuProps={{ classes: { paper: "dataTableSelectMenuListWrapper" } }}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    },
    filterFn: (row: RowData, columnId: string, filterValue: string) => {
      if (typeof filterValue !== "string" || filterValue === "") return true;
      const rowValue = row[columnId];
      return (filterValue === "yes" && rowValue) || (filterValue === "no" && !rowValue);
    },
  };
};

export const createStatusColumn = <T extends { [key in keyof T]: any }>(
  accessorKey: any,
  header: string
): MRT_ColumnDef<T> => {
  return {
    enableSorting: false,
    accessorKey,
    header,
    size: 120,
    Cell: ({ cell }) => {
      const value = cell.getValue<string>();
      switch (value) {
        case LABELS.SUBMITTED:
          return LABELS.SUBMITTED;
        case LABELS.APPROVED:
          return LABELS.APPROVED;
        case LABELS.REJECTED:
          return LABELS.REJECTED;
        default:
          return "Unknown";
      }
    },
    Filter: ({ column }) => {
      const filterValue = (column.getFilterValue() as string) || "";
      return (
        <Box className="dataTableSelectOptWrapper">
          <FormControl fullWidth>
            <Select
              value={filterValue}
              onChange={(e) => column.setFilterValue(e.target.value ? [e.target.value] : undefined)}
              displayEmpty
              MenuProps={{ classes: { paper: "dataTableSelectMenuListWrapper" } }}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value={LABELS.SUBMITTED}>{LABELS.SUBMITTED}</MenuItem>
              <MenuItem value={LABELS.APPROVED}>{LABELS.APPROVED}</MenuItem>
              <MenuItem value={LABELS.REJECTED}>{LABELS.REJECTED}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    },
  };
};

export const createUoMColumn = <T extends { [key in keyof T]: any }>(
  accessorKey: any,
  header: string
): MRT_ColumnDef<T> => {
  return {
    enableSorting: false,
    accessorKey,
    header,
    size: 120,
    Cell: ({ cell }) => {
      const value = cell.getValue<string>();
      switch (value) {
        case "LB":
          return "LB";
        case "KG":
          return "KG";
        default:
          return "Unknown";
      }
    },
    Filter: ({ column }) => {
      const filterValue = (column.getFilterValue() as string) || "";
      return (
        <Box className="dataTableSelectOptWrapper">
          <FormControl fullWidth>
            <Select
              value={filterValue}
              onChange={(e) => column.setFilterValue(e.target.value || undefined)}
              displayEmpty
              MenuProps={{ classes: { paper: "dataTableSelectMenuListWrapper" } }}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="LB">LB</MenuItem>
              <MenuItem value="KG">KG</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
    },
  };
};

// Generate parameters for API request based on Search
export const generateSearchUrlParams = (searchParams: { id: string; value: string }[]): Record<string, string> => {
  const searchObject = searchParams.reduce((acc, { id, value }) => {
    return { ...acc, [id]: value };
  }, {} as Record<string, string>);
  return { search: JSON.stringify(searchObject) };
};

// Generate parameters for API request based on filters
export const generateParams = (filters: Filter): Record<string, any> => {
  const { column, sorting, pagination, user_type, email } = filters;
  const params: Record<string, any> = {};
  params.search = column.reduce((acc, col) => {
    acc[col.id] = col.value;
    return acc;
  }, {} as Record<string, any>);
  if (isNotEmpty(params.search)) params.search = JSON.stringify(params.search);
  if (sorting.length) {
    const { id, desc } = sorting[0];
    params.sort = JSON.stringify([id, desc ? "desc" : "asc"]);
  }
  if (pagination) {
    params.limit = pagination.pageSize;
    params.offset = pagination.pageIndex * pagination.pageSize;
  }
  if (user_type) params.user_type = user_type;
  if (email) params.email = email;
  return params;
};

export const generateParamsForDropdown = (filters: Filter): Record<string, any> => {
  const { column } = filters;
  const params: Record<string, any> = {};
  params.search = column.reduce((acc, col) => {
    acc[col.id] = col.value;
    return acc;
  }, {} as Record<string, any>);
  if (isNotEmpty(params.search)) params.search = JSON.stringify(params.search);
  return params;
};

// Check if an object is not empty
export const isNotEmpty = <T extends object>(obj: T): boolean => Object.keys(obj).length > 0;

/**
 * Conditionally sets field values in a Formik form, assigning only values that are not `null` or `undefined`.
 */
export const setFormikValues = (formik: any, data: Record<string, any>) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      formik.setFieldValue(key, value);
    }
  });
};

export const dynamicSortByAlphabet = <T, K extends keyof T>(arr: T[], key: K): T[] =>
  arr.sort((a, b) => String(a[key]).trim().localeCompare(String(b[key]).trim()));

export const getNextYearEndDate = (dateString: string): string => {
  const date = new Date(dateString);
  const nextYear = date.getFullYear() + 1;
  const month = date.getMonth();
  const lastDay = new Date(nextYear, month + 1, 0).getDate();
  const nextDate = new Date(nextYear, month, lastDay);
  return nextDate.toLocaleDateString("en-US");
};

export const shouldDisableSelect = (array: any[]) => array.length === 0;

export const getInitials = (firstName: string, lastName: string): string => {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
};

export const removeLeadingZerosFromProductId = (
  products: { product_id: string; product_name: string; sales_text: string }[]
) => {
  return products.map((product) => ({
    ...product,
    product_id: product.product_id.replace(/^0+/, ""),
  }));
};

export const getNext60DaysISO = () => {
  const today = new Date();
  const next10Days = new Date(today);
  next10Days.setDate(today.getDate() + 60);
  return next10Days.toISOString();
};

export const getPrevious60DaysISO = () => {
  const today = new Date();
  const previous10Days = new Date(today);
  previous10Days.setDate(today.getDate() - 60);
  return previous10Days.toISOString();
};

export const getCurrentDateISO = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

// Request Form Utils Function
export const removeFields = (formData: Record<string, any>, fields: string[]) => {
  fields.forEach((field) => delete formData[field]);
};

export const checkForChanges = (formData: any, modalData: any, dispatch: any) => {
  const mergedData = { ...modalData, ...formData };
  const isChanged = JSON.stringify(mergedData) !== JSON.stringify(modalData);
  if (!isChanged) dispatch(showAlert({ message: msg.NO_CHANGES_DETECTED, alertType: "info" }));
  return { isChanged, mergedData };
};

export const getModalTitle = (baseTitle: string, modalType: any) => {
  switch (modalType) {
    case MODAL_ACTION_ADD:
      return `Add ${baseTitle}`;
    case MODAL_ACTION_EDIT:
      return `Edit ${baseTitle}`;
    case MODAL_ACTION_VIEW:
      return `View ${baseTitle}`;
    default:
      return baseTitle;
  }
};

export const isModalEditOrViewMode = (modalType: string | null): boolean =>
  modalType === MODAL_ACTION_EDIT || modalType === MODAL_ACTION_VIEW;

export const isModalEditMode = (modalType: string | null): boolean => modalType === MODAL_ACTION_EDIT;

export const isModalViewMode = (modalType: string | null): boolean => modalType === MODAL_ACTION_VIEW;

export const getFullName = (userDetails: { first_name: string; last_name: string }): string =>
  `${userDetails.first_name} ${userDetails.last_name}`;

export const createTitle = (str: string): string => {
  const spacedStr = str.replace(/-/g, " ");
  const titleCasedStr = spacedStr.replace(/\b\w/g, (letter) => letter.toUpperCase());
  return titleCasedStr;
};

export const decodeJwt = (token: string) => {
  const decodeBase64Url = (base64Url: string) => {
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    while (base64.length % 4) {
      base64 += "=";
    }
    return decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
  };

  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT format");
  }

  const payload = JSON.parse(decodeBase64Url(parts[1]));
  return payload;
};

export const hasFiltersChanged = (userFilters: any[]): boolean => {
  const defaultFilter = [{ id: "status", value: ["Submitted"] }];
  // If the lengths are different, data has changed
  if (defaultFilter.length !== userFilters.length) return true;

  // Check each item in defaultFilter against targetData
  return defaultFilter.some((item, index) => {
    const targetItem = userFilters[index];

    // If `id` is different, data has changed
    if (item.id !== targetItem.id) return true;

    // Check if `value` is different
    const itemValue = Array.isArray(item.value) ? JSON.stringify(item.value) : item.value;
    const targetValue = Array.isArray(targetItem.value) ? JSON.stringify(targetItem.value) : targetItem.value;

    // If `value` is different, data has changed
    return itemValue !== targetValue;
  });
};

/**
 * Converts `valid_from` and `valid_to` fields in the filters array to Date objects if they contain valid date strings.
 * Non-date fields or invalid dates remain unchanged.
 */
export const formatDateColumnsInFilters = (filters: any[]) => {
  return filters.map((filter) => {
    if (filter.id === "valid_from" || filter.id === "valid_to") {
      return { ...filter, value: dayjs(filter.value) };
    }
    return filter;
  });
};
