import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../store/slices/userSlice";
import { accountApi } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS, USER_ROLES } from "../utils/constants";
import { decodeJwt } from "../utils/helpers";

const useUserRole = () => {
  const [userRole, setUserRole] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const role = USER_ROLES.getRoleByCurrentUrl();
    setUserRole(role);
    if (role === USER_ROLES.INTERNAL) {
      fetchInternalUserData();
    } else if (role === USER_ROLES.EXTERNAL) {
      externalUserData();
    }
  }, []);

  const fetchInternalUserData = async () => {
    try {
      const data = await accountApi.getAccountDetails();
      if (data) {
        const decodedData = decodeJwt(data.token);
        const userData = {
          first_name: decodedData.given_name,
          last_name: decodedData.family_name,
          email: decodedData.unique_name,
          role: USER_ROLES.INTERNAL,
          token: data.token,
        };
        updateUserData(userData);
      }
    } catch (error) {
      console.error("Failed to fetch internal user details:", error);
    }
  };

  const externalUserData = () => {
    const userData = localStorage.getItem(USER_ROLES.USER_DETAILS);
    if (!userData) {
      // Added this condition so users can access pages like reset-password without being redirected to the login page. In short, auth pages are accessible without login.
      const isAuthPages = location.pathname.includes(ROUTE_PATHS.AUTH);
      if (!isAuthPages) navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
    } else {
      updateUserData(JSON.parse(userData));
    }
  };

  const updateUserData = (userData: any) => {
    dispatch(setUserDetails(userData));
    localStorage.setItem(USER_ROLES.USER_DETAILS, JSON.stringify(userData));
  };

  return userRole;
};

export default useUserRole;
