import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { NAV_LINKS } from "../../../utils/constants";
import { Fragment } from "react/jsx-runtime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Images from "../../../assets/images";

interface MobileMenuContentProps {
  toggleMobileMenu: () => void;
  handleLogout: () => void;
  currentRole: string;
}

const MobileMenuContent = ({ toggleMobileMenu, handleLogout, currentRole }: MobileMenuContentProps) => (
  <Box onClick={toggleMobileMenu} sx={{ textAlign: "center" }} className="drawerMenuWrapper">
    <Box className="logoWrap" sx={{ display: "flex" }} component={NavLink} to="/">
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <img src={Images.HeaderLogo} alt="CUPS Logo" className="mainLogo" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
    <Divider />
    <List>
      {NAV_LINKS.map((navItem) => (
        <Fragment key={navItem.title}>
          {!navItem.subLinks && navItem.role.includes(currentRole) && (
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <Link
                  component={NavLink}
                  to={navItem.path}
                  sx={{ "&.active": { color: "#4cbcec" } }}
                  className="sideNavLink">
                  {navItem.title}
                </Link>
              </ListItemButton>
            </ListItem>
          )}
          {navItem.subLinks && (
            <>
              <Accordion onClick={(e) => e.stopPropagation()} className="sideNavAccordionWrap">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{navItem.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {navItem.subLinks.map((subNavItem) =>
                    subNavItem.role.includes(currentRole) ? (
                      <ListItem key={subNavItem.title} disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }} onClick={toggleMobileMenu}>
                          <Link
                            component={NavLink}
                            to={subNavItem.path}
                            sx={{ "&.active": { color: "#4cbcec" } }}
                            className="sideNavLink">
                            {subNavItem.title}
                          </Link>
                        </ListItemButton>
                      </ListItem>
                    ) : null
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Fragment>
      ))}
      <ListItem disablePadding>
        <ListItemButton sx={{ textAlign: "center" }}>
          <Link onClick={handleLogout} className="sideNavLink logout">
            Logout
          </Link>
        </ListItemButton>
      </ListItem>
    </List>
  </Box>
);

export default MobileMenuContent;
