export const API_PATHS = {
  INTERNAL_BASE_URL: "https://cups.api.533267023544.aws.glpoly.net/cups/",
  EXTERNAL_BASE_URL: "https://api.cups-qa.covestro.com/cups/",
  LOCAL_BASE_URL: "http://localhost:8080/cups/", // If want to connect local server
  COUNTRY: "country",
  STATE: "state",
  BUSINESS_ENTITY: "business_entity",
  MARKET: "market",
  DISTRIBUTOR: "distributor",
  DISTRIBUTOR_CUSTOMER: "distributor_customer",
  DISTRIBUTOR_CONTACT: "distributor_contact",
  COMPETITOR: "competitor",
  SELLER: "seller",
  DISTRIBUTOR_LOCATION: "distributor_location",
  OEM: "oem",
  PRODUCT_INFORMATION: "product_information",
  REQUEST: "request",
  SAVED_FILTER: "saved_filter",
};
