import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { AlertMessage, CommonCheckboxField, CommonTextField, ContainerGrid } from "../index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideModal, resetModalState } from "../../store/slices/modalSlice";
import { LABELS, MESSAGES as msg } from "../../utils/constants";
import {
  checkForChanges,
  getModalTitle,
  isModalEditMode,
  isModalEditOrViewMode,
  isModalViewMode,
  setFormikValues,
} from "../../utils/helpers";
import { DistributorData } from "../../api/type";
import { distributorApi } from "../../api";
import "./Modals.scss";

interface Props {
  handleSubmit: (formData: any) => void;
}

const validationSchema = yup.object({
  first_name: yup.string().label(LABELS.FIRST_NAME).required(),
  last_name: yup.string().label(LABELS.LAST_NAME).required(),
  email: yup.string().label(LABELS.EMAIL).required().email(msg.VALID_EMAIL),
  connected_distributor: yup.array().min(1, msg.AT_LEAST_DISTRIBUTOR).required(),
  is_obsolete: yup.boolean().label(LABELS.OBSOLETE),
});

const AddOrEditDistributorContactModal = ({ handleSubmit }: Props) => {
  const { isVisible, modalType, modalData } = useAppSelector((state) => state.modal);
  const [distributorDropdown, setDistributorDropdown] = useState<DistributorData[]>([]);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      connected_distributor: [] as number[],
      is_obsolete: false,
    },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      const { isChanged, mergedData } = checkForChanges(formData, modalData, dispatch);
      if (isChanged) {
        handleSubmit(mergedData);
        dispatch(hideModal());
        dispatch(resetModalState());
      }
    },
  });

  useEffect(() => {
    if (isVisible) fetchDropdown();
  }, [isVisible]);

  useEffect(() => {
    if (isModalEditOrViewMode(modalType)) {
      setFormikValues(formik, modalData);
      const distributorIds = modalData.distributor_data.map((item: any) => item.distributor_id);
      formik.setFieldValue("connected_distributor", distributorIds);
    }
  }, [distributorDropdown]);

  const fetchDropdown = async () => {
    const payloadIsObsolete = { column: [{ id: "is_obsolete", value: "false" }] } as any;
    const { data } = await distributorApi.getDistributorForDropdown(payloadIsObsolete);
    setDistributorDropdown(data.data);
  };

  const isViewMode = isModalViewMode(modalType);

  const handleModalExited = () => {
    formik.resetForm();
    dispatch(resetModalState());
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="sm"
      open={isVisible}
      scroll="body"
      onClose={() => dispatch(hideModal())}
      TransitionProps={{ onExited: handleModalExited }}
      className="cmnModalWrapper addOrEditDistributorContactModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{getModalTitle("Distributor Contact", modalType)}</DialogTitle>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <AlertMessage show={isModalEditMode(modalType)} />
            <Grid size={6}>
              <CommonTextField
                name="first_name"
                label={`${LABELS.FIRST_NAME}*`}
                formikProps={{ field: formik.getFieldProps("first_name"), meta: formik.getFieldMeta("first_name") }}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={6}>
              <CommonTextField
                name="last_name"
                label={`${LABELS.LAST_NAME}*`}
                formikProps={{ field: formik.getFieldProps("last_name"), meta: formik.getFieldMeta("last_name") }}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                type="email"
                name="email"
                label={`${LABELS.EMAIL}*`}
                formikProps={{ field: formik.getFieldProps("email"), meta: formik.getFieldMeta("email") }}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <Box className="commonInputWrap">
                <FormControl
                  fullWidth
                  disabled={isViewMode}
                  error={formik.touched.connected_distributor && Boolean(formik.errors.connected_distributor)}>
                  <InputLabel>{`${LABELS.SELECT_DISTRIBUTORS}*`}</InputLabel>
                  <Select
                    multiple
                    value={formik.values.connected_distributor}
                    onChange={(event) => {
                      const value = event.target.value as number[];
                      formik.setFieldValue("connected_distributor", value);
                    }}
                    input={<OutlinedInput label={`${LABELS.SELECT_DISTRIBUTORS}*`} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((selectedId) => {
                          const selectedDistributor = distributorDropdown.find(
                            (distributor) => distributor.id === selectedId
                          );
                          return <Chip key={selectedId} label={selectedDistributor?.distributor_name} />;
                        })}
                      </Box>
                    )}>
                    {distributorDropdown.map((distributor) => (
                      <MenuItem key={distributor.id} value={distributor.id}>
                        {distributor.distributor_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.connected_distributor && formik.errors.connected_distributor && (
                    <FormHelperText>{formik.errors.connected_distributor}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            {isModalEditOrViewMode(modalType) && (
              <Grid size={12}>
                <CommonCheckboxField
                  name="is_obsolete"
                  label={`${LABELS.OBSOLETE}*`}
                  formikProps={{ field: formik.getFieldProps("is_obsolete"), meta: formik.getFieldMeta("is_obsolete") }}
                  disabled={isViewMode}
                />
              </Grid>
            )}
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" className="cancelBtn" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button>
          {!isViewMode && (
            <Button variant="contained" color="info" type="submit" className="submitBtn">
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrEditDistributorContactModal;
