import { useState, useEffect, useRef } from "react";
import { AddOrEditBusinessEntityModal, CommonPageLayout } from "../../../components";
import { businessEntityApi } from "../../../api";
import { BusinessEntityData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";

const columns: MRT_ColumnDef<BusinessEntityData>[] = [
  { accessorKey: "entity_name", header: `${LABELS.BUSINESS_ENTITY} ${LABELS.NAME}` },
  createBooleanColumn<BusinessEntityData>("is_ep", LABELS.IS_EP),
  createBooleanColumn<BusinessEntityData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const BusinessEntity = () => {
  const dispatch = useAppDispatch();
  const [dataTable, setDataTable] = useState<BusinessEntityData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const { filters } = useAppSelector((state) => state.dataTable);
  const [filtersReset, setFiltersReset] = useState(false);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) fetchData();
  }, [filters, filtersReset]);

  useEffect(() => {
    if (isInitialRender.current) isInitialRender.current = false;
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await businessEntityApi.getBusinessEntity(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: BusinessEntityData) => {
    try {
      if (formData.id) await businessEntityApi.updateBusinessEntity(formData);
      else await businessEntityApi.createBusinessEntity(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Business Entity"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditBusinessEntityModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default BusinessEntity;
