import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { AlertMessage, CommonAutocomplete, CommonCheckboxField, CommonTextField, ContainerGrid } from "../index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideModal, resetModalState } from "../../store/slices/modalSlice";
import { LABELS } from "../../utils/constants";
import {
  checkForChanges,
  getModalTitle,
  isModalEditMode,
  isModalEditOrViewMode,
  isModalViewMode,
  setFormikValues,
} from "../../utils/helpers";
import "./Modals.scss";

interface Props {
  handleSubmit: (formData: any) => void;
}

const validationSchema = yup.object({
  distributor_customer: yup.string().label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}`).required(),
  customer_number: yup.string().label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`).required(),
  distributor_id: yup.string().label(LABELS.DISTRIBUTOR).required(),
  country_id: yup.string().label(LABELS.COUNTRY).required(),
  state_id: yup.string().label(LABELS.STATE).required(),
  city: yup.string().label(LABELS.CITY).required(),
  is_obsolete: yup.boolean().label(LABELS.OBSOLETE),
});

const AddOrEditDistributorCustomerModal = ({ handleSubmit }: Props) => {
  const { isVisible, modalType, modalData } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      distributor_customer: "",
      customer_number: "",
      distributor_id: null,
      country_id: null,
      state_id: null,
      city: "",
      is_obsolete: false,
    },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      const { isChanged, mergedData } = checkForChanges(formData, modalData, dispatch);
      if (isChanged) {
        handleSubmit(mergedData);
        dispatch(hideModal());
        dispatch(resetModalState());
      }
    },
  });

  useEffect(() => {
    if (isModalEditOrViewMode(modalType)) setFormikValues(formik, modalData);
  }, [isVisible, modalData]);

  const isViewMode = isModalViewMode(modalType);

  const handleModalExited = () => {
    formik.resetForm();
    dispatch(resetModalState());
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="sm"
      open={isVisible}
      scroll="body"
      onClose={() => dispatch(hideModal())}
      TransitionProps={{ onExited: handleModalExited }}
      className="cmnModalWrapper addOrEditDistributorCustomerModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{getModalTitle("Distributor Customer", modalType)}</DialogTitle>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <AlertMessage show={isModalEditMode(modalType)} />
            <Grid size={12}>
              <CommonTextField
                name="distributor_customer"
                label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}*`}
                formikProps={{
                  field: formik.getFieldProps("distributor_customer"),
                  meta: formik.getFieldMeta("distributor_customer"),
                }}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                name="customer_number"
                label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}*`}
                formikProps={{
                  field: formik.getFieldProps("customer_number"),
                  meta: formik.getFieldMeta("customer_number"),
                }}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonAutocomplete
                label={`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR}*`}
                value={formik.values.distributor_id}
                onChange={(value) => formik.setFieldValue("distributor_id", value)}
                apiType="distributor"
                optionLabelKey="distributor_name"
                error={formik.touched.distributor_id && Boolean(formik.errors.distributor_id)}
                helperText={formik.touched.distributor_id && formik.errors.distributor_id}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonAutocomplete
                label={`${LABELS.CHOOSE} ${LABELS.COUNTRY}*`}
                value={formik.values.country_id}
                onChange={(value) => formik.setFieldValue("country_id", value)}
                apiType="country"
                optionLabelKey="name"
                error={formik.touched.country_id && Boolean(formik.errors.country_id)}
                helperText={formik.touched.country_id && formik.errors.country_id}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonAutocomplete
                label={`${LABELS.CHOOSE} ${LABELS.STATE}*`}
                value={formik.values.state_id}
                onChange={(value) => formik.setFieldValue("state_id", value)}
                apiType="state"
                optionLabelKey="name"
                error={formik.touched.state_id && Boolean(formik.errors.state_id)}
                helperText={formik.touched.state_id && formik.errors.state_id}
                disabled={isViewMode}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                name="city"
                label={`${LABELS.CITY}*`}
                formikProps={{
                  field: formik.getFieldProps("city"),
                  meta: formik.getFieldMeta("city"),
                }}
                disabled={isViewMode}
              />
            </Grid>
            {isModalEditMode(modalType) && (
              <Grid size={12}>
                <CommonCheckboxField
                  name="is_obsolete"
                  label={`${LABELS.OBSOLETE}`}
                  formikProps={{ field: formik.getFieldProps("is_obsolete"), meta: formik.getFieldMeta("is_obsolete") }}
                  disabled={isViewMode}
                />
              </Grid>
            )}
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" className="cancelBtn" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button>
          {!isViewMode && (
            <Button variant="contained" color="info" type="submit" className="submitBtn">
              Submit
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrEditDistributorCustomerModal;
