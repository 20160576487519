import Grid from "@mui/material/Grid2";
import { useState, useEffect, useRef } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { LABELS, MESSAGES as msg, ROUTE_PATHS } from "../../../utils/constants";
import { DataTable, PageTitle, ContainerGrid, AddOrUpdateSavedFiltersModal, Loader } from "../../../components";
import { RequestData, SavedFilterData } from "../../../api/type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestApi, savedFilterApi } from "../../../api";
import { resetOrAddFilters } from "../../../store/slices/dataTableSlice";
import { formatDateColumnsInFilters, hasFiltersChanged } from "../../../utils/helpers";
import { MODAL_ACTION_ADD, showModal } from "../../../store/slices/modalSlice";
import { showAlert } from "../../../store/slices/alertSnackbarSlice";
import { NON_OEM_LAYOUT_COLUMNS, OEM_LAYOUT_COLUMNS, initialColumnFilter, initialSortingFilter } from "../contant";
import { RootState } from "../../../store";

interface Categories {
  id: string;
  name: string;
}

// This is hardcoded. It should be dynamic from the API.
const categories: Categories[] = [
  { id: "1", name: "EP" },
  { id: "2", name: "CA" },
];

const InternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInitialRender = useRef(true);

  const { filters } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [activeCategory, setActiveCategory] = useState<string>(categories[0].id);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>(OEM_LAYOUT_COLUMNS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtersReset, setFiltersReset] = useState<boolean>(false);
  const [savedFiltersDropdown, setSavedFiltersDropdown] = useState<SavedFilterData[]>([]);
  const [isSavedFiltersDropdownLoading, setIsSavedFiltersDropdownLoading] = useState<boolean>(false);
  const [hasFetchedSavedFilter, setHasFetchedSavedFilter] = useState<boolean>(false);
  const [activeSavedFilter, setActiveSavedFilter] = useState<string>("");

  const handleTableRowClick = (rowData: any) => navigateToRequestDetail(rowData.id, LABELS.EDIT);

  const checkActionType = (event: { type: string; data: any }) => {
    if (event.type === LABELS.CLONE) {
      navigateToRequestDetail(event.data.id, LABELS.CLONE);
    }
  };

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const applySelectedSavedFilter = (event: SelectChangeEvent) => {
    const selectedFilterId = event.target.value;
    setActiveSavedFilter(selectedFilterId);
    const selectedFilter = savedFiltersDropdown.find((filter) => filter.id === Number(selectedFilterId));
    if (selectedFilter) {
      const filterColumns = formatDateColumnsInFilters(selectedFilter.filter.savedColumns);
      dispatch(resetOrAddFilters({ column: filterColumns }));
    } else {
      dispatch(resetOrAddFilters({ sorting: initialSortingFilter }));
    }
  };

  const loadSavedFiltersOnDropdownOpen = () => {
    if (!hasFetchedSavedFilter) {
      setIsSavedFiltersDropdownLoading(true);
      fetchSavedFilters();
    }
  };

  const resetState = () => {
    setIsLoading(true);
    setSavedFiltersDropdown([]);
    setActiveSavedFilter("");
    setHasFetchedSavedFilter(false);
    dispatch(resetOrAddFilters({ column: initialColumnFilter }));
  };

  const saveFilterData = async (formData: { id?: number; filter_name?: string }) => {
    // Check if there are any filters to save
    if (!hasFiltersChanged(filters.column)) {
      dispatch(showAlert({ message: msg.NO_FILTER_TO_SAVE, alertType: "warning" }));
      return;
    }

    const oemLayout = activeCategory === "1";
    const savedColumns = filters.column;
    const userEmail = userDetails.email;

    const payload = {
      filter: { oem_layout: oemLayout, savedColumns: savedColumns },
      user_email: userEmail,
    };

    try {
      if (formData.id) {
        await savedFilterApi.updateSavedFilter({ ...payload, id: formData.id });
      } else if (formData.filter_name) {
        await savedFilterApi.createSavedFilter({
          ...payload,
          filter_name: formData.filter_name,
        });
      }
      setHasFetchedSavedFilter(false);
    } catch (err) {
      console.error("Failed to save filters:", err);
    }
  };

  const fetchSavedFilters = async () => {
    try {
      const { data } = await savedFilterApi.getSavedFilter(userDetails.email);
      // Determine if the OEM layout filter should be applied
      const isOemLayout = activeCategory === "1";
      // Filter the saved filters based on OEM layout
      const filteredSavedFilters = filterByOemLayout(data.data, isOemLayout);
      setSavedFiltersDropdown(filteredSavedFilters);
      setHasFetchedSavedFilter(true);
      setIsSavedFiltersDropdownLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchRequestData = async () => {
    try {
      const updatedFilters = {
        ...filters,
        column: [...filters.column, { id: "business_entity_id", value: activeCategory }],
      };
      const columnsToSet = activeCategory === "1" ? OEM_LAYOUT_COLUMNS : NON_OEM_LAYOUT_COLUMNS;
      setColumns(columnsToSet);
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterByOemLayout = (filters: SavedFilterData[], isOemLayoutEnabled: boolean) => {
    return filters.filter((filter) => filter.filter.oem_layout === isOemLayoutEnabled);
  };

  useEffect(() => {
    dispatch(resetOrAddFilters({ column: initialColumnFilter, sorting: initialSortingFilter }));
    setFiltersReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (filtersReset && !isInitialRender.current) {
      fetchRequestData();
    }
  }, [filters, filtersReset]);

  useEffect(() => {
    if (!isInitialRender.current) {
      resetState();
    }
    isInitialRender.current = false;
  }, [activeCategory]);

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={activeCategory}
                label="Select Category"
                onChange={(e) => setActiveCategory(e.target.value)}>
                {categories.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ mr: 2, padding: "15px" }}
                onClick={() => dispatch(showModal({ type: MODAL_ACTION_ADD }))}>
                Save Filters
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 160 }}>
                <InputLabel>Apply Saved Filter</InputLabel>
                <Select
                  value={activeSavedFilter}
                  label="Apply Saved Filter"
                  onChange={applySelectedSavedFilter}
                  onOpen={loadSavedFiltersOnDropdownOpen}>
                  {isSavedFiltersDropdownLoading ? (
                    <MenuItem disabled>
                      <em>Loading...</em>
                    </MenuItem>
                  ) : (
                    [
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...savedFiltersDropdown.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.filter_name}
                        </MenuItem>
                      )),
                    ]
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </ContainerGrid>
      {!isLoading && (
        <ContainerGrid>
          <Grid size={12}>
            <DataTable
              data={dataTable}
              totalRows={totalRows}
              columns={columns}
              exportFileName="Request"
              actionButton={{ clone: true, edit: true }}
              handleActionButton={checkActionType}
              handleTableRowClick={handleTableRowClick}
            />
          </Grid>
        </ContainerGrid>
      )}
      {isLoading && <Loader />}
      <AddOrUpdateSavedFiltersModal handleSubmit={saveFilterData} />
    </Box>
  );
};

export default InternalUser;
