import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserDetails {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  token: string;
  UID?: string;
}

interface UserState {
  userDetails: UserDetails;
}

const initialState: UserState = {
  userDetails: {
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    token: "",
    UID: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    clearUserDetails: (state) => initialState,
  },
});

export const { setUserDetails, clearUserDetails } = userSlice.actions;
export default userSlice.reducer;
