import { FormEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import {
  Button,
  FormControl,
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  countryApi,
  stateApi,
  distributorApi,
  distributorLocationApi,
  distributorCustomerApi,
  sellerApi,
  marketApi,
  oemApi,
  competitorApi,
  productInformationApi,
  requestApi,
  distributorContactApi,
} from "../../../api";
import { DistributorData } from "../../../api/type";
import {
  dynamicSortByAlphabet,
  getFullName,
  getNextYearEndDate,
  removeFields,
  removeLeadingZerosFromProductId,
  shouldDisableSelect,
} from "../../../utils/helpers";
import {
  CommonCheckboxField,
  CommonTextField,
  ConfirmationModal,
  ContainerGrid,
  ToggleExistingNewButtonGroup,
} from "../../../components";
import { LABELS, ROUTE_PATHS, MESSAGES as msg, USER_ROLES } from "../../../utils/constants";
import { BUTTON_INITIAL_VALUES, FIELD_NAMES } from "./typeAndSchema/constants";
import { DropdownStates } from "./typeAndSchema/types";
import { baseValidationSchema } from "./typeAndSchema/validationSchema";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { showAlert } from "../../../store/slices/alertSnackbarSlice";
import "../Requests.scss";

const CreateRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.user);
  const [validationSchema, setValidationSchema] = useState(baseValidationSchema);
  const { id } = useParams<{ id: string }>();

  const DATE = new Date();
  const todayDate = `${DATE.getMonth() + 1}/${DATE.getDate()}/${DATE.getFullYear()}`;
  const nextYearDate = getNextYearEndDate(todayDate);
  const DISCOUNT_OPTIONS = Array.from({ length: 20 }, (_, i) => i + 1);

  const formik = useFormik({
    initialValues: {
      [FIELD_NAMES.STATUS]: "",
      [FIELD_NAMES.REQUESTED_BY]: getFullName(userDetails),
      [FIELD_NAMES.REQUESTED_DATE]: todayDate,
      [FIELD_NAMES.REQUESTED_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]: "",
      [FIELD_NAMES.UNIT_OF_MEASUREMENT]: "LB",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: "",
      [FIELD_NAMES.CUSTOMER_NUMBER]: "",
      [FIELD_NAMES.COUNTRY_ID]: "",
      [FIELD_NAMES.STATE_ID]: "",
      [FIELD_NAMES.CITY]: "",
      [FIELD_NAMES.SELLER_ID]: "",
      [FIELD_NAMES.MARKET_ID]: "",
      [FIELD_NAMES.APPLICATION]: "",
      [FIELD_NAMES.OEM_ID]: "",
      [FIELD_NAMES.OEM_NAME]: "",
      [FIELD_NAMES.COMPETITOR_ID]: "",
      [FIELD_NAMES.COMPETITOR_NAME]: "",
      [FIELD_NAMES.COMPETITOR_VOLUME]: "",
      [FIELD_NAMES.SALES_TEXT]: "",
      [FIELD_NAMES.PRODUCT_ID]: "",
      [FIELD_NAMES.PRODUCT_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER]: "",
      [FIELD_NAMES.THIRD_PARTY]: false,
      [FIELD_NAMES.VALID_FROM]: dayjs(todayDate) || "",
      [FIELD_NAMES.VALID_TO]: dayjs(nextYearDate),
      [FIELD_NAMES.MINIMUM_QUANTITY]: "",
      [FIELD_NAMES.LIST_PRICE]: "",
      [FIELD_NAMES.LIST_DISCOUNT]: "",
      [FIELD_NAMES.REQUESTED_PRICE]: "",
      [FIELD_NAMES.REQUESTED_DISCOUNT]: "",
      [FIELD_NAMES.INTO_STOCK_PRICE]: "",
      [FIELD_NAMES.SUPPORT_PRICE]: "",
      [FIELD_NAMES.ADJUSTMENT]: "",
      [FIELD_NAMES.COMMENT]: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const [dropdownStates, setDropdownStates] = useState<DropdownStates>({
    distributor: [],
    distributorLocation: [],
    distributorCustomer: [],
    seller: [],
    market: [],
    oem: [],
    competitor: [],
    productInformation: [],
    country: [],
    state: [],
  });
  const [selectedDistributorData, setSelectedDistributorData] = useState<DistributorData>();
  const [isDistributorCA, setIsDistributorCA] = useState<boolean>(false);
  const [existingNewButtonValues, setExistingNewButtonValues] = useState(BUTTON_INITIAL_VALUES);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formType = queryParams.get("type");
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const resetFieldsRelatedToDistributorChange = (isBEChange = false) => {
    // Reset fields for distributor and related entities
    resetFields([
      FIELD_NAMES.DISTRIBUTOR_ID,
      FIELD_NAMES.DISTRIBUTOR_LOCATION_ID,
      FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID,
      FIELD_NAMES.DISTRIBUTOR_CUSTOMER,
      FIELD_NAMES.CUSTOMER_NUMBER,
      FIELD_NAMES.COUNTRY_ID,
      FIELD_NAMES.STATE_ID,
      FIELD_NAMES.CITY,
      FIELD_NAMES.SELLER_ID,
      FIELD_NAMES.MARKET_ID,
      FIELD_NAMES.OEM_ID,
      FIELD_NAMES.OEM_NAME,
      FIELD_NAMES.COMPETITOR_ID,
      FIELD_NAMES.COMPETITOR_NAME,
      FIELD_NAMES.SALES_TEXT,
      FIELD_NAMES.PRODUCT_ID,
      FIELD_NAMES.PRODUCT_NAME,
    ]);

    // Reset the selected distributor data and related dropdown states
    setSelectedDistributorData(undefined);
    setDropdownStates((prevStates) => ({
      ...prevStates,
      distributorLocation: [],
      distributorCustomer: [],
      seller: [],
      competitor: [],
      productInformation: [],
    }));

    // Reset additional distributor-related state variables
    setExistingNewButtonValues(BUTTON_INITIAL_VALUES);

    // If isBEChange is true, reset the fields related to 'Covestro Special Pricing Information'
    if (isBEChange) {
      resetFields([
        FIELD_NAMES.LIST_PRICE,
        FIELD_NAMES.LIST_DISCOUNT,
        FIELD_NAMES.REQUESTED_PRICE,
        FIELD_NAMES.REQUESTED_DISCOUNT,
        FIELD_NAMES.INTO_STOCK_PRICE,
        FIELD_NAMES.SUPPORT_PRICE,
        FIELD_NAMES.ADJUSTMENT,
      ]);
      setIsDistributorCA(false);
    }
  };

  // Reference to track the previous distributor's state
  const prevDistributorIdRef = useRef<DistributorData | undefined>(selectedDistributorData);
  const handleSelectedDistributor = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const selected = dropdownStates.distributor.find((a) => a.id === Number(value));
    if (selected) {
      // If a previous distributor exists, reset relevant fields
      if (prevDistributorIdRef.current) {
        // Reset only the fields related to distributor, ignoring 'Covestro Special Pricing Information' fields
        resetFieldsRelatedToDistributorChange(false);
        // If the entity name has changed (e.g., from EP to CA or CA to EP), reset 'Covestro Special Pricing Information' fields
        if (prevDistributorIdRef.current.entity_name !== selected.entity_name) {
          resetFieldsRelatedToDistributorChange(true);
        }
      }
      // Update the ref to the current selected distributor
      prevDistributorIdRef.current = selected;
    }
    if (selected) {
      const { id, entity_name, sold_to_number } = selected;
      formik.setFieldValue(FIELD_NAMES.DISTRIBUTOR_ID, id);
      setSelectedDistributorData(selected);
      setIsDistributorCA(entity_name.toLowerCase() === "ca");
      const payload = {
        column: [
          { id: "distributor_id", value: id },
          { id: "is_obsolete", value: "false" },
        ],
      };
      fetchOptions("distributorLocation", payload);
      fetchOptions("distributorCustomer", payload);
      fetchOptions("oem", payload);
      fetchOptions("competitor", payload);
      fetchOptions("productInformation", sold_to_number);
    }
  };

  const handleSelectedDistributorCustomer = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const selected = dropdownStates.distributorCustomer.find((a) => a.id === Number(value));
    if (selected) {
      const { id, customer_number, country_id, state_id, city, distributor_id } = selected;
      formik.setFieldValue(FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID, id);
      formik.setFieldValue(FIELD_NAMES.CUSTOMER_NUMBER, customer_number ?? "");
      formik.setFieldValue(FIELD_NAMES.COUNTRY_ID, country_id);
      formik.setFieldValue(FIELD_NAMES.STATE_ID, state_id);
      formik.setFieldValue(FIELD_NAMES.CITY, city);
      const sellerPayload = {
        column: [
          { id: "distributor_id", value: distributor_id },
          { id: "country_id", value: country_id },
          { id: "state_id", value: state_id },
          { id: "is_obsolete", value: "false" },
        ],
      };
      fetchOptions("seller", sellerPayload);
    }
  };

  const handleSelectedOem = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const selected = dropdownStates.oem.find((a) => a.id === Number(value));
    if (selected) {
      const { id, third_party } = selected;
      formik.setFieldValue(FIELD_NAMES.OEM_ID, id);
      formik.setFieldValue(FIELD_NAMES.THIRD_PARTY, third_party);
    }
  };

  const handleSelectedProductInformation = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const selected = dropdownStates.productInformation.find((a) => a.product_id === value);
    if (selected) {
      const { product_id, product_name } = selected;
      formik.setFieldValue(FIELD_NAMES.SALES_TEXT, product_id);
      formik.setFieldValue(FIELD_NAMES.PRODUCT_ID, product_id);
      formik.setFieldValue(FIELD_NAMES.PRODUCT_NAME, product_name);
    }
  };

  const fetchOptions = async (apiType: string, payload?: any) => {
    let response: any;
    const payloadIsObsolete = { column: [{ id: "is_obsolete", value: "false" }] } as any;
    switch (apiType) {
      case "country":
        response = await countryApi.getCountryForDropdown(payloadIsObsolete);
        setDropdownStates((prev) => ({
          ...prev,
          country: dynamicSortByAlphabet(response.data.data, "name"),
        }));
        break;
      case "state":
        response = await stateApi.getStateForDropdown(payloadIsObsolete);
        setDropdownStates((prev) => ({
          ...prev,
          state: dynamicSortByAlphabet(response.data.data, "name"),
        }));
        break;
      case "stateByCountry":
        response = await stateApi.getStateForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          state: dynamicSortByAlphabet(response.data.data, "name"),
        }));
        break;
      case "distributor":
        const distributorResponse = await distributorApi.getDistributorForDropdown(payloadIsObsolete);
        const allDistributors = distributorResponse.data.data;

        // For external users, filter distributors by email
        if (userDetails.role === USER_ROLES.EXTERNAL) {
          const filterPayload = [
            { id: "is_obsolete", value: "false" },
            // { id: "email", value: "usman@test3.com" },
            { id: "email", value: userDetails.email },
          ];
          const userDistributorsResponse = await distributorContactApi.getDistributorByEmail(filterPayload);
          const userDistributors = userDistributorsResponse.data.data;
          if (userDistributors.length === 0) {
            // If no distributors are associated with the user, handle this case
            dispatch(showAlert({ message: msg.NO_DISTRIBUTORS_FOUND, alertType: "error" }));
            setDropdownStates((prev) => ({ ...prev, distributor: [] }));
          } else {
            // Filter distributors by matching IDs from the user's associated distributors
            const filterDistributorsByUser = (userDistributors: any, allDistributors: any) => {
              const userDistributorIds = userDistributors[0].distributor_data.map((dist: any) => dist.distributor_id);
              return allDistributors.filter((dist: any) => userDistributorIds.includes(dist.id));
            };
            const filteredDistributors = filterDistributorsByUser(userDistributors, allDistributors);
            setDropdownStates((prev) => ({
              ...prev,
              distributor: dynamicSortByAlphabet(filteredDistributors, "distributor_name"),
            }));
          }
        } else {
          // For non-external users, set dropdown without filtering
          setDropdownStates((prev) => ({
            ...prev,
            distributor: dynamicSortByAlphabet(allDistributors, "distributor_name"),
          }));
        }
        break;
      case "market":
        response = await marketApi.getMarketForDropdown(payloadIsObsolete);
        setDropdownStates((prev) => ({
          ...prev,
          market: dynamicSortByAlphabet(response.data.data, "market_name"),
        }));
        break;
      case "distributorLocation":
        response = await distributorLocationApi.getDistributorLocationForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          distributorLocation: dynamicSortByAlphabet(response.data.data, "location"),
        }));
        break;
      case "distributorCustomer":
        response = await distributorCustomerApi.getDistributorCustomerForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          distributorCustomer: dynamicSortByAlphabet(response.data.data, "distributor_customer"),
        }));
        break;
      case "oem":
        response = await oemApi.getOemForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          oem: dynamicSortByAlphabet(response.data.data, "oem_name"),
        }));
        break;
      case "seller":
        response = await sellerApi.getSellerForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          seller: dynamicSortByAlphabet(response.data.data, "seller_name"),
        }));
        break;
      case "competitor":
        response = await competitorApi.getCompetitorForDropdown(payload);
        setDropdownStates((prev) => ({
          ...prev,
          competitor: dynamicSortByAlphabet(response.data.data, "competitor_name"),
        }));
        break;
      case "productInformation":
        response = await productInformationApi.getProductInformation(payload);
        const productsData = removeLeadingZerosFromProductId(response.data.data);
        setDropdownStates((prev) => ({
          ...prev,
          productInformation: dynamicSortByAlphabet(productsData, "product_name"),
        }));
        break;
    }
  };

  useEffect(() => {
    const apiTypes = ["distributor", "country", "state", "market"];
    apiTypes.forEach((type) => fetchOptions(type));
  }, []);

  const handleExistingNewButtonChange = (key: string) => (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue) {
      setExistingNewButtonValues((prevValues) => ({
        ...prevValues,
        [key]: newValue,
      }));
    }
  };

  // Refs to store previous values
  const prevValuesRef = useRef(existingNewButtonValues);
  const isFirstLoad = useRef(true);
  useEffect(() => {
    let updatedSchema = {};
    const prevValues = prevValuesRef.current;

    const updateCustomerSchema = () => {
      if (existingNewButtonValues.accountMarketing === LABELS.EXISTING) {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: yup
            .string()
            .label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}`)
            .required(),
          [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: yup
            .string()
            .label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}`),
        };
      } else {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: yup.string().label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}`),
          [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: yup
            .string()
            .label(`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}`)
            .required(),
        };
      }
    };

    const updateCompetitorSchema = () => {
      if (existingNewButtonValues.competitor === LABELS.EXISTING) {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.COMPETITOR_ID]: yup.string().label(LABELS.COMPETITOR_NAME).required(),
          [FIELD_NAMES.COMPETITOR_NAME]: yup.string().label(LABELS.COMPETITOR_NAME),
        };
      } else {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.COMPETITOR_ID]: yup.string().label(LABELS.COMPETITOR_NAME),
          [FIELD_NAMES.COMPETITOR_NAME]: yup.string().label(LABELS.COMPETITOR_NAME).required(),
        };
      }
    };

    const updateOemSchema = () => {
      if (existingNewButtonValues.oem === LABELS.EXISTING) {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.OEM_ID]: yup.string().label(LABELS.OEM_NAME).required(),
          [FIELD_NAMES.OEM_NAME]: yup.string().label(LABELS.OEM_NAME),
        };
      } else {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.OEM_ID]: yup.string().label(LABELS.OEM_NAME),
          [FIELD_NAMES.OEM_NAME]: yup.string().label(LABELS.OEM_NAME).required(),
        };
      }
    };

    const updatePriceSchema = () => {
      if (!isDistributorCA) {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.LIST_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.LIST_PRICE}`)
            .label(LABELS.LIST_PRICE)
            .required(),
          [FIELD_NAMES.LIST_DISCOUNT]: yup.string().label(LABELS.DISCOUNT).required(),
          [FIELD_NAMES.REQUESTED_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.REQUESTED}`)
            .label(LABELS.REQUESTED)
            .required(),
          [FIELD_NAMES.REQUESTED_DISCOUNT]: yup.string().label(LABELS.DISCOUNT).required(),
          [FIELD_NAMES.INTO_STOCK_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.INTO_STOCK_PRICE}`)
            .label(LABELS.INTO_STOCK_PRICE),
          [FIELD_NAMES.SUPPORT_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.SUPPORT_PRICE}`)
            .label(LABELS.SUPPORT_PRICE),
        };
      } else {
        updatedSchema = {
          ...updatedSchema,
          [FIELD_NAMES.OEM_ID]: yup.string().label(LABELS.OEM_NAME),
          [FIELD_NAMES.OEM_NAME]: yup.string().label(LABELS.OEM_NAME),
          [FIELD_NAMES.LIST_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.LIST_PRICE}`)
            .label(LABELS.LIST_PRICE),
          [FIELD_NAMES.LIST_DISCOUNT]: yup.string().label(LABELS.DISCOUNT),
          [FIELD_NAMES.REQUESTED_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.REQUESTED}`)
            .label(LABELS.REQUESTED),
          [FIELD_NAMES.REQUESTED_DISCOUNT]: yup.string().label(LABELS.DISCOUNT),
          [FIELD_NAMES.INTO_STOCK_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.INTO_STOCK_PRICE}`)
            .label(LABELS.INTO_STOCK_PRICE)
            .required(),
          [FIELD_NAMES.SUPPORT_PRICE]: yup
            .number()
            .typeError(`${msg.PLS_ENTER_VALID_NUMBER} ${LABELS.SUPPORT_PRICE}`)
            .label(LABELS.SUPPORT_PRICE)
            .required(),
        };
      }
    };

    if (isFirstLoad.current) {
      updateCustomerSchema();
      updateCompetitorSchema();
      updateOemSchema();
      isFirstLoad.current = false;
    } else {
      if (existingNewButtonValues.accountMarketing !== prevValues.accountMarketing) {
        setDropdownStates((prevStates) => ({ ...prevStates, seller: [] }));
        resetFields([
          FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID,
          FIELD_NAMES.DISTRIBUTOR_CUSTOMER,
          FIELD_NAMES.CUSTOMER_NUMBER,
          FIELD_NAMES.COUNTRY_ID,
          FIELD_NAMES.STATE_ID,
          FIELD_NAMES.CITY,
          FIELD_NAMES.SELLER_ID,
        ]);
        updateCustomerSchema();
      }
      if (existingNewButtonValues.competitor !== prevValues.competitor) {
        resetFields([FIELD_NAMES.COMPETITOR_ID, FIELD_NAMES.COMPETITOR_NAME]);
        updateCompetitorSchema();
      }
      if (existingNewButtonValues.oem !== prevValues.oem) {
        resetFields([FIELD_NAMES.OEM_ID, FIELD_NAMES.OEM_NAME, FIELD_NAMES.THIRD_PARTY], "");
        updateOemSchema();
      }
    }

    updatePriceSchema();

    setValidationSchema((prevSchema: yup.ObjectSchema<any>) => {
      const mergedSchema = baseValidationSchema.shape({
        ...prevSchema.fields,
        ...updatedSchema,
      });
      return mergedSchema;
    });
    // Update the ref with the current values for the next comparison
    prevValuesRef.current = existingNewButtonValues;
  }, [existingNewButtonValues, isDistributorCA]);

  // useEffect(() => {
  //   const schemaDescription = validationSchema.describe();
  //   // const competitorSchema = {
  //   //   competitor_id: schemaDescription.fields.competitor_id,
  //   //   competitor_name: schemaDescription.fields.competitor_name,
  //   //   oem_id: schemaDescription.fields.oem_id,
  //   //   oem_name: schemaDescription.fields.oem_name,
  //   // };
  //   // console.log("Competitor related fields:", JSON.stringify(competitorSchema, null, 2));
  //   console.log("Current validation schema description:", JSON.stringify(schemaDescription, null, 2));
  // }, [validationSchema]);

  const resetFields = (fields: string[], defaultValue: any = "") => {
    fields.forEach((field) => {
      const valueToSet = field === FIELD_NAMES.THIRD_PARTY ? false : defaultValue;
      formik.setFieldValue(field, valueToSet);
      formik.setFieldTouched(field, false);
    });
  };

  useEffect(() => {
    const { COUNTRY_ID, STATE_ID, DISTRIBUTOR_ID } = FIELD_NAMES;
    const isNewAccount = existingNewButtonValues.accountMarketing === LABELS.NEW;
    const countryId = formik.values[COUNTRY_ID];
    const stateId = formik.values[STATE_ID];
    const distributorId = formik.values[DISTRIBUTOR_ID];

    if (countryId && stateId && isNewAccount) {
      const sellerPayload = {
        column: [
          { id: "distributor_id", value: distributorId },
          { id: "country_id", value: countryId },
          { id: "state_id", value: stateId },
          { id: "is_obsolete", value: "false" },
        ],
      };
      fetchOptions("seller", sellerPayload);
    }

    if (countryId && isNewAccount) {
      const statePayload = {
        column: [
          { id: "country_id", value: countryId },
          { id: "is_obsolete", value: "false" },
        ],
      };
      fetchOptions("stateByCountry", statePayload);
    }
  }, [formik.values[FIELD_NAMES.COUNTRY_ID], formik.values[FIELD_NAMES.STATE_ID]]);

  useEffect(() => {
    const shouldAdjustForEP =
      !isDistributorCA &&
      formik.values[FIELD_NAMES.LIST_PRICE] &&
      formik.values[FIELD_NAMES.LIST_DISCOUNT] &&
      formik.values[FIELD_NAMES.REQUESTED_PRICE] &&
      formik.values[FIELD_NAMES.REQUESTED_DISCOUNT];

    const shouldAdjustForCA =
      isDistributorCA && formik.values[FIELD_NAMES.INTO_STOCK_PRICE] && formik.values[FIELD_NAMES.SUPPORT_PRICE];

    if (shouldAdjustForEP) adjustmentForEP();
    else if (shouldAdjustForCA) adjustmentForCA();
  }, [
    formik.values[FIELD_NAMES.LIST_PRICE],
    formik.values[FIELD_NAMES.LIST_DISCOUNT],
    formik.values[FIELD_NAMES.REQUESTED_PRICE],
    formik.values[FIELD_NAMES.REQUESTED_DISCOUNT],
    formik.values[FIELD_NAMES.INTO_STOCK_PRICE],
    formik.values[FIELD_NAMES.SUPPORT_PRICE],
    isDistributorCA,
  ]);

  const adjustmentForEP = () => {
    const listPrice = parseFloat(formik.values[FIELD_NAMES.LIST_PRICE] ?? "0") || 0;
    const listDiscount = parseFloat(formik.values[FIELD_NAMES.LIST_DISCOUNT] ?? "0") || 0;
    const requestedPrice = parseFloat(formik.values[FIELD_NAMES.REQUESTED_PRICE] ?? "0") || 0;
    const requestedDiscount = parseFloat(formik.values[FIELD_NAMES.REQUESTED_DISCOUNT] ?? "0") || 0;

    const discountedListPrice = listPrice - (listPrice * listDiscount) / 100;
    const discountedRequestedPrice = requestedPrice - (requestedPrice * requestedDiscount) / 100;

    const adjustment = discountedListPrice - discountedRequestedPrice;
    formik.setFieldValue(FIELD_NAMES.ADJUSTMENT, adjustment.toFixed(4));
  };

  const adjustmentForCA = () => {
    const intoStockPrice = parseFloat(formik.values[FIELD_NAMES.INTO_STOCK_PRICE] ?? "0") || 0;
    const supportPrice = parseFloat(formik.values[FIELD_NAMES.SUPPORT_PRICE] ?? "0") || 0;
    const adjustment = intoStockPrice - supportPrice;
    formik.setFieldValue(FIELD_NAMES.ADJUSTMENT, adjustment.toFixed(4));
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault(); // Prevent default form submission
    const updatedFormData = modifiyFormData();
    const errors = await formik.validateForm();
    const isFormValid = Object.keys(errors).length === 0;
    if (isFormValid) {
      submitForm(updatedFormData);
    } else {
      console.error("Form validation errors:", errors);
      formik.handleSubmit();
    }
  };

  const modifiyFormData = () => {
    const formData = { ...formik.values } as Record<string, any>;

    let distributorCustomerData: Record<string, any> = {};
    let oemData: Record<string, any> = {};
    let competitorData: Record<string, any> = {};

    if (existingNewButtonValues.accountMarketing === LABELS.EXISTING) {
      removeFields(formData, [
        FIELD_NAMES.DISTRIBUTOR_CUSTOMER,
        FIELD_NAMES.CUSTOMER_NUMBER,
        FIELD_NAMES.COUNTRY_ID,
        FIELD_NAMES.STATE_ID,
        FIELD_NAMES.CITY,
      ]);
    } else {
      distributorCustomerData = {
        [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: formData[FIELD_NAMES.DISTRIBUTOR_CUSTOMER],
        [FIELD_NAMES.CUSTOMER_NUMBER]: formData[FIELD_NAMES.CUSTOMER_NUMBER],
        [FIELD_NAMES.COUNTRY_ID]: formData[FIELD_NAMES.COUNTRY_ID],
        [FIELD_NAMES.STATE_ID]: formData[FIELD_NAMES.STATE_ID],
        [FIELD_NAMES.CITY]: formData[FIELD_NAMES.CITY],
      };
      formData["distributor_customer_data"] = distributorCustomerData;
      removeFields(formData, [
        FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID,
        FIELD_NAMES.DISTRIBUTOR_CUSTOMER,
        FIELD_NAMES.CUSTOMER_NUMBER,
        FIELD_NAMES.COUNTRY_ID,
        FIELD_NAMES.STATE_ID,
        FIELD_NAMES.CITY,
      ]);
    }

    if (existingNewButtonValues.oem === LABELS.EXISTING && !isDistributorCA) {
      removeFields(formData, [FIELD_NAMES.OEM_NAME, FIELD_NAMES.THIRD_PARTY]);
    } else if (existingNewButtonValues.oem === LABELS.NEW && !isDistributorCA) {
      oemData = {
        [FIELD_NAMES.OEM_NAME]: formData[FIELD_NAMES.OEM_NAME],
        [FIELD_NAMES.THIRD_PARTY]: formData[FIELD_NAMES.THIRD_PARTY],
      };
      formData["oem_data"] = oemData;
      removeFields(formData, [FIELD_NAMES.OEM_ID, FIELD_NAMES.OEM_NAME, FIELD_NAMES.THIRD_PARTY]);
    } else if (isDistributorCA) {
      removeFields(formData, [FIELD_NAMES.OEM_ID, FIELD_NAMES.OEM_NAME, FIELD_NAMES.THIRD_PARTY]);
    }

    if (existingNewButtonValues.competitor === LABELS.EXISTING) {
      removeFields(formData, [FIELD_NAMES.COMPETITOR_NAME]);
    } else {
      competitorData = { [FIELD_NAMES.COMPETITOR_NAME]: formData[FIELD_NAMES.COMPETITOR_NAME] };
      formData["competitor_data"] = competitorData;
      removeFields(formData, [FIELD_NAMES.COMPETITOR_ID, FIELD_NAMES.COMPETITOR_NAME]);
    }

    if (!isDistributorCA) {
      removeFields(formData, [FIELD_NAMES.INTO_STOCK_PRICE, FIELD_NAMES.SUPPORT_PRICE]);
    } else {
      removeFields(formData, [
        FIELD_NAMES.LIST_PRICE,
        FIELD_NAMES.LIST_DISCOUNT,
        FIELD_NAMES.REQUESTED_PRICE,
        FIELD_NAMES.REQUESTED_DISCOUNT,
      ]);
    }

    removeFields(formData, [FIELD_NAMES.REQUESTED_ID, FIELD_NAMES.STATUS]);

    const salesTextById = dropdownStates.productInformation.find(
      (a) => a.product_id === formData[FIELD_NAMES.PRODUCT_ID]
    );
    if (salesTextById) formData[FIELD_NAMES.SALES_TEXT] = salesTextById.sales_text;

    formData[FIELD_NAMES.COMPETITOR_VOLUME] = formData[FIELD_NAMES.COMPETITOR_VOLUME] || null;

    // Format dates to MM/DD/YYYY
    const formattedValidFrom = dayjs(formData[FIELD_NAMES.VALID_FROM]).format("MM/DD/YYYY");
    const formattedValidTo = dayjs(formData[FIELD_NAMES.VALID_TO]).format("MM/DD/YYYY");
    formData[FIELD_NAMES.VALID_FROM] = formattedValidFrom;
    formData[FIELD_NAMES.VALID_TO] = formattedValidTo;

    return formData;
  };

  const submitForm = async (payload: any) => {
    console.log("FINAL SUBMIT PAYLOAD>", payload);
    try {
      if (payload.id) await requestApi.updateRequest(payload);
      else await requestApi.createRequest(payload);
      navigate(`/${ROUTE_PATHS.REQUESTS}`);
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  const handleCloseConfirmationModal = (type: string) => {
    setOpenConfirmationModal(false);
    if (type === "yes") navigate(`/${ROUTE_PATHS.REQUESTS}`);
  };

  return (
    <Box className="priceSupportRequestPageContainer">
      <form onSubmit={handleFormSubmit}>
        <Box className="requestInfoWrapper">
          <ContainerGrid>
            <Grid size={12}>
              <Box className="headingWrapper">
                <h1 className="heading">Price Support Request</h1>
              </Box>
            </Grid>
          </ContainerGrid>
          <ContainerGrid>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.REQUESTED_BY}
                  label={`${LABELS.REQUESTED_BY}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.REQUESTED_BY),
                    meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_BY),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
              {formik.values[FIELD_NAMES.REQUESTED_ID] && (
                <Box sx={{ marginBottom: "25px" }}>
                  <CommonTextField
                    name={FIELD_NAMES.REQUESTED_ID}
                    label={`${LABELS.REQUESTED_ID}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.REQUESTED_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Box>
              )}
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.REQUESTED_DATE}
                  label={`${LABELS.REQUESTED_DATE}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.REQUESTED_DATE),
                    meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_DATE),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <FormControl
                fullWidth
                error={
                  formik.touched[FIELD_NAMES.DISTRIBUTOR_ID] && Boolean(formik.errors[FIELD_NAMES.DISTRIBUTOR_ID])
                }>
                <InputLabel>{`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR}*`}</InputLabel>
                <Select
                  name={FIELD_NAMES.DISTRIBUTOR_ID}
                  value={formik.values[FIELD_NAMES.DISTRIBUTOR_ID]}
                  label={`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR}*`}
                  onChange={handleSelectedDistributor}
                  onBlur={formik.handleBlur}>
                  {dropdownStates.distributor.map((distributor) => (
                    <MenuItem key={distributor.id} value={distributor.id}>
                      {distributor.distributor_name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched[FIELD_NAMES.DISTRIBUTOR_ID] && formik.errors[FIELD_NAMES.DISTRIBUTOR_ID] && (
                  <FormHelperText>{formik.errors[FIELD_NAMES.DISTRIBUTOR_ID]}</FormHelperText>
                )}
              </FormControl>
              <Box className="distributorInfoWrap">
                <p>
                  {LABELS.SALES_ORGANISATION}: {selectedDistributorData?.sales_orgnisation}
                </p>
                <p>
                  {LABELS.BUSINESS_ENTITY}: {selectedDistributorData?.entity_name}
                </p>
                <p>
                  {LABELS.SOLD_NUMBER}: {selectedDistributorData?.sold_to_number}
                </p>
              </Box>
            </Grid>
          </ContainerGrid>
        </Box>
        <Box className="requestFormWrapper">
          <ContainerGrid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID] &&
                      Boolean(formik.errors[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID])
                    }>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}*`}</InputLabel>
                    <Select
                      disabled={shouldDisableSelect(dropdownStates.distributorLocation)}
                      name={FIELD_NAMES.DISTRIBUTOR_LOCATION_ID}
                      value={formik.values[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.distributorLocation.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.location}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID] &&
                      formik.errors[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID] && (
                        <FormHelperText>{formik.errors[FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={
                      formik.touched[FIELD_NAMES.UNIT_OF_MEASUREMENT] &&
                      Boolean(formik.errors[FIELD_NAMES.UNIT_OF_MEASUREMENT])
                    }>
                    <InputLabel>{`${LABELS.UNIT_OF_MEASUREMENT}*`}</InputLabel>
                    <Select
                      name={FIELD_NAMES.UNIT_OF_MEASUREMENT}
                      value={formik.values[FIELD_NAMES.UNIT_OF_MEASUREMENT]}
                      label={`${LABELS.UNIT_OF_MEASUREMENT}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      <MenuItem value="LB">LB</MenuItem>
                      <MenuItem value="KG">KG</MenuItem>
                    </Select>
                    {formik.touched[FIELD_NAMES.UNIT_OF_MEASUREMENT] &&
                      formik.errors[FIELD_NAMES.UNIT_OF_MEASUREMENT] && (
                        <FormHelperText>{formik.errors[FIELD_NAMES.UNIT_OF_MEASUREMENT]}</FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Distributor Seller</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME}
                    label={`${LABELS.FIRST_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME}
                    label={`${LABELS.LAST_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Account/Marketing Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={12}>
                  <ToggleExistingNewButtonGroup
                    value={existingNewButtonValues.accountMarketing}
                    label="Account/Marketing Information"
                    onChange={handleExistingNewButtonChange("accountMarketing")}
                  />
                </Grid>
                {existingNewButtonValues.accountMarketing === LABELS.EXISTING && (
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID] &&
                        Boolean(formik.errors[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID])
                      }>
                      <InputLabel>{`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}*`}</InputLabel>
                      <Select
                        name={FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID}
                        value={formik.values[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]}
                        label={`${LABELS.CHOOSE} ${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}*`}
                        onChange={handleSelectedDistributorCustomer}
                        onBlur={formik.handleBlur}>
                        {dropdownStates.distributorCustomer.map((opt) => (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.distributor_customer}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID] &&
                        formik.errors[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID] && (
                          <FormHelperText>{formik.errors[FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]}</FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                )}
                {existingNewButtonValues.accountMarketing === LABELS.NEW && (
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.DISTRIBUTOR_CUSTOMER}
                      label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_CUSTOMER),
                        meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_CUSTOMER),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.CUSTOMER_NUMBER}
                    label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.CUSTOMER_NUMBER),
                      meta: formik.getFieldMeta(FIELD_NAMES.CUSTOMER_NUMBER),
                    }}
                    variant="outlined"
                    disabled={existingNewButtonValues.accountMarketing === LABELS.EXISTING}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.COUNTRY_ID] && Boolean(formik.errors[FIELD_NAMES.COUNTRY_ID])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.COUNTRY}*`}</InputLabel>
                    <Select
                      disabled={existingNewButtonValues.accountMarketing === LABELS.EXISTING}
                      name={FIELD_NAMES.COUNTRY_ID}
                      value={formik.values[FIELD_NAMES.COUNTRY_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.COUNTRY}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.country.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.COUNTRY_ID] && formik.errors[FIELD_NAMES.COUNTRY_ID] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.COUNTRY_ID]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.STATE_ID] && Boolean(formik.errors[FIELD_NAMES.STATE_ID])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.STATE}*`}</InputLabel>
                    <Select
                      disabled={existingNewButtonValues.accountMarketing === LABELS.EXISTING}
                      name={FIELD_NAMES.STATE_ID}
                      value={formik.values[FIELD_NAMES.STATE_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.STATE}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.state.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.STATE_ID] && formik.errors[FIELD_NAMES.STATE_ID] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.STATE_ID]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <CommonTextField
                    name={FIELD_NAMES.CITY}
                    label={`${LABELS.CITY}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.CITY),
                      meta: formik.getFieldMeta(FIELD_NAMES.CITY),
                    }}
                    variant="outlined"
                    disabled={existingNewButtonValues.accountMarketing === LABELS.EXISTING}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.SELLER_ID] && Boolean(formik.errors[FIELD_NAMES.SELLER_ID])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.COVESTRO_SELLER}*`}</InputLabel>
                    <Select
                      disabled={shouldDisableSelect(dropdownStates.seller)}
                      name={FIELD_NAMES.SELLER_ID}
                      value={formik.values[FIELD_NAMES.SELLER_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.COVESTRO_SELLER}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.seller.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.seller_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.SELLER_ID] && formik.errors[FIELD_NAMES.SELLER_ID] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.SELLER_ID]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.MARKET_ID] && Boolean(formik.errors[FIELD_NAMES.MARKET_ID])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.MARKET_NAME}*`}</InputLabel>
                    <Select
                      name={FIELD_NAMES.MARKET_ID}
                      value={formik.values[FIELD_NAMES.MARKET_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.MARKET_NAME}*`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.market.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.market_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.MARKET_ID] && formik.errors[FIELD_NAMES.MARKET_ID] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.MARKET_ID]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.APPLICATION}
                    label={`${LABELS.APPLICATION}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.APPLICATION),
                      meta: formik.getFieldMeta(FIELD_NAMES.APPLICATION),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            {!isDistributorCA && (
              <>
                <Grid size={12}>
                  <p className="heading">OEM</p>
                </Grid>
                <Grid size={12}>
                  <ContainerGrid disableGutters={true}>
                    <Grid size={12}>
                      <ToggleExistingNewButtonGroup
                        value={existingNewButtonValues.oem}
                        label="OEM"
                        onChange={handleExistingNewButtonChange("oem")}
                      />
                    </Grid>
                    {existingNewButtonValues.oem === LABELS.EXISTING && (
                      <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                        <FormControl
                          fullWidth
                          error={formik.touched[FIELD_NAMES.OEM_ID] && Boolean(formik.errors[FIELD_NAMES.OEM_ID])}>
                          <InputLabel>{`${LABELS.CHOOSE} ${LABELS.OEM_NAME}*`}</InputLabel>
                          <Select
                            disabled={shouldDisableSelect(dropdownStates.oem)}
                            name={FIELD_NAMES.OEM_ID}
                            value={formik.values[FIELD_NAMES.OEM_ID]}
                            label={`${LABELS.CHOOSE} ${LABELS.OEM_NAME}*`}
                            onChange={handleSelectedOem}
                            onBlur={formik.handleBlur}>
                            {dropdownStates.oem.map((opt) => (
                              <MenuItem key={opt.id} value={opt.id}>
                                {opt.oem_name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched[FIELD_NAMES.OEM_ID] && formik.errors[FIELD_NAMES.OEM_ID] && (
                            <FormHelperText>{formik.errors[FIELD_NAMES.OEM_ID]}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    {existingNewButtonValues.oem === LABELS.NEW && (
                      <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                        <CommonTextField
                          name={FIELD_NAMES.OEM_NAME}
                          label={`${LABELS.OEM_NAME}*`}
                          formikProps={{
                            field: formik.getFieldProps(FIELD_NAMES.OEM_NAME),
                            meta: formik.getFieldMeta(FIELD_NAMES.OEM_NAME),
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                      <CommonCheckboxField
                        name={FIELD_NAMES.THIRD_PARTY}
                        label={`${LABELS.THIRD_PARTY_CONTRACT}`}
                        formikProps={{
                          field: formik.getFieldProps(FIELD_NAMES.THIRD_PARTY),
                          meta: formik.getFieldMeta(FIELD_NAMES.THIRD_PARTY),
                        }}
                        disabled={existingNewButtonValues.oem === LABELS.EXISTING}
                      />
                    </Grid>
                  </ContainerGrid>
                </Grid>
              </>
            )}
            <Grid size={12}>
              <p className="heading">Covestro Competitor Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={12}>
                  <ToggleExistingNewButtonGroup
                    value={existingNewButtonValues.competitor}
                    label="Covestro Competitor Information"
                    onChange={handleExistingNewButtonChange("competitor")}
                  />
                </Grid>
                {existingNewButtonValues.competitor === LABELS.EXISTING && (
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched[FIELD_NAMES.COMPETITOR_ID] && Boolean(formik.errors[FIELD_NAMES.COMPETITOR_ID])
                      }>
                      <InputLabel>{`${LABELS.CHOOSE} ${LABELS.COMPETITOR_NAME}*`}</InputLabel>
                      <Select
                        disabled={shouldDisableSelect(dropdownStates.competitor)}
                        name={FIELD_NAMES.COMPETITOR_ID}
                        value={formik.values[FIELD_NAMES.COMPETITOR_ID]}
                        label={`${LABELS.CHOOSE} ${LABELS.COMPETITOR_NAME}*`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {dropdownStates.competitor.map((opt) => (
                          <MenuItem key={opt.id} value={opt.id}>
                            {opt.competitor_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched[FIELD_NAMES.COMPETITOR_ID] && formik.errors[FIELD_NAMES.COMPETITOR_ID] && (
                        <FormHelperText>{formik.errors[FIELD_NAMES.COMPETITOR_ID]}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                {existingNewButtonValues.competitor === LABELS.NEW && (
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.COMPETITOR_NAME}
                      label={`${LABELS.COMPETITOR_NAME}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.COMPETITOR_NAME),
                        meta: formik.getFieldMeta(FIELD_NAMES.COMPETITOR_NAME),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                )}
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.COMPETITOR_VOLUME}
                    label={`${LABELS.COMPETITOR_VOLUME_YEAR}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.COMPETITOR_VOLUME),
                      meta: formik.getFieldMeta(FIELD_NAMES.COMPETITOR_VOLUME),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Product Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.SALES_TEXT] && Boolean(formik.errors[FIELD_NAMES.SALES_TEXT])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.PRODUCT_SALES_TEXT}*`}</InputLabel>
                    <Select
                      disabled={shouldDisableSelect(dropdownStates.productInformation)}
                      name={FIELD_NAMES.SALES_TEXT}
                      value={formik.values[FIELD_NAMES.SALES_TEXT]}
                      label={`${LABELS.CHOOSE} ${LABELS.PRODUCT_SALES_TEXT}*`}
                      onChange={handleSelectedProductInformation}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.productInformation.map((opt, i) => (
                        <MenuItem key={`${i}${opt.sales_text}`} value={opt.product_id}>
                          {opt.sales_text}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.SALES_TEXT] && formik.errors[FIELD_NAMES.SALES_TEXT] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.SALES_TEXT]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched[FIELD_NAMES.PRODUCT_ID] && Boolean(formik.errors[FIELD_NAMES.PRODUCT_ID])}>
                    <InputLabel>{`${LABELS.CHOOSE} ${LABELS.COVESTRO_PRODUCT_NUMBER}*`}</InputLabel>
                    <Select
                      disabled={shouldDisableSelect(dropdownStates.productInformation)}
                      name={FIELD_NAMES.PRODUCT_ID}
                      value={formik.values[FIELD_NAMES.PRODUCT_ID]}
                      label={`${LABELS.CHOOSE} ${LABELS.COVESTRO_PRODUCT_NUMBER}*`}
                      onChange={handleSelectedProductInformation}
                      onBlur={formik.handleBlur}>
                      {dropdownStates.productInformation.map((opt, i) => (
                        <MenuItem key={`${i}${opt.product_id}`} value={opt.product_id}>
                          {opt.product_id}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched[FIELD_NAMES.PRODUCT_ID] && formik.errors[FIELD_NAMES.PRODUCT_ID] && (
                      <FormHelperText>{formik.errors[FIELD_NAMES.PRODUCT_ID]}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.PRODUCT_NAME}
                    label={`${LABELS.COVESTRO_PRODUCT}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.PRODUCT_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.PRODUCT_NAME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER}
                    label={`${LABELS.DISTRIBUTOR_PRODUCT_NUMBER}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Covestro Special Pricing Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <DatePicker
                    minDate={dayjs()}
                    label={`${LABELS.EFFECTIVE_DATE}*`}
                    value={dayjs(formik.values[FIELD_NAMES.VALID_FROM])}
                    onChange={(newValue) => formik.setFieldValue(FIELD_NAMES.VALID_FROM, newValue)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: formik.touched[FIELD_NAMES.VALID_FROM] && Boolean(formik.errors[FIELD_NAMES.VALID_FROM]),
                        helperText: formik.touched[FIELD_NAMES.VALID_FROM]
                          ? (formik.errors[FIELD_NAMES.VALID_FROM] as string)
                          : "",
                      },
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <DatePicker
                    minDate={dayjs()}
                    label={`${LABELS.EXPIRATION_DATE}*`}
                    value={dayjs(formik.values[FIELD_NAMES.VALID_TO])}
                    onChange={(newValue) => formik.setFieldValue(FIELD_NAMES.VALID_TO, newValue)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: formik.touched[FIELD_NAMES.VALID_TO] && Boolean(formik.errors[FIELD_NAMES.VALID_TO]),
                        helperText: formik.touched[FIELD_NAMES.VALID_TO]
                          ? (formik.errors[FIELD_NAMES.VALID_TO] as string)
                          : "",
                      },
                    }}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.MINIMUM_QUANTITY}
                    label={`${LABELS.MINIMUM_QTY_PURCHASE}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.MINIMUM_QUANTITY),
                      meta: formik.getFieldMeta(FIELD_NAMES.MINIMUM_QUANTITY),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            {isDistributorCA && (
              <Grid size={12}>
                <ContainerGrid disableGutters={true}>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.INTO_STOCK_PRICE}
                      label={`${LABELS.INTO_STOCK_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.INTO_STOCK_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.INTO_STOCK_PRICE),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.SUPPORT_PRICE}
                      label={`${LABELS.SUPPORT_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.SUPPORT_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.SUPPORT_PRICE),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                </ContainerGrid>
              </Grid>
            )}
            {!isDistributorCA && (
              <Grid size={12}>
                <ContainerGrid disableGutters={true}>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
                    <span>Into Stock Price</span>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.LIST_PRICE}
                      label={`${LABELS.LIST_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.LIST_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.LIST_PRICE),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched[FIELD_NAMES.LIST_DISCOUNT] && Boolean(formik.errors[FIELD_NAMES.LIST_DISCOUNT])
                      }>
                      <InputLabel>{`${LABELS.CHOOSE} ${LABELS.DISCOUNT}*`}</InputLabel>
                      <Select
                        name={FIELD_NAMES.LIST_DISCOUNT}
                        value={formik.values[FIELD_NAMES.LIST_DISCOUNT]}
                        label={`${LABELS.CHOOSE} ${LABELS.DISCOUNT}*`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {DISCOUNT_OPTIONS.map((discount) => (
                          <MenuItem key={discount} value={discount}>
                            {discount}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched[FIELD_NAMES.LIST_DISCOUNT] && formik.errors[FIELD_NAMES.LIST_DISCOUNT] && (
                        <FormHelperText>{formik.errors[FIELD_NAMES.LIST_DISCOUNT]}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
                    <span>Supported Price</span>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.REQUESTED_PRICE}
                      label={`${LABELS.REQUESTED}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.REQUESTED_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_PRICE),
                      }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched[FIELD_NAMES.REQUESTED_DISCOUNT] &&
                        Boolean(formik.errors[FIELD_NAMES.REQUESTED_DISCOUNT])
                      }>
                      <InputLabel>{`${LABELS.CHOOSE} ${LABELS.DISCOUNT}*`}</InputLabel>
                      <Select
                        name={FIELD_NAMES.REQUESTED_DISCOUNT}
                        value={formik.values[FIELD_NAMES.REQUESTED_DISCOUNT]}
                        label={`${LABELS.CHOOSE} ${LABELS.DISCOUNT}*`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}>
                        {DISCOUNT_OPTIONS.map((discount) => (
                          <MenuItem key={discount} value={discount}>
                            {discount}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched[FIELD_NAMES.REQUESTED_DISCOUNT] &&
                        formik.errors[FIELD_NAMES.REQUESTED_DISCOUNT] && (
                          <FormHelperText>{formik.errors[FIELD_NAMES.REQUESTED_DISCOUNT]}</FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </ContainerGrid>
              </Grid>
            )}
            <Grid size={12}>
              <CommonTextField
                name={FIELD_NAMES.ADJUSTMENT}
                label={`${LABELS.ADJUSTMENT}*`}
                formikProps={{
                  field: formik.getFieldProps(FIELD_NAMES.ADJUSTMENT),
                  meta: formik.getFieldMeta(FIELD_NAMES.ADJUSTMENT),
                }}
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid size={12}>
              <CommonTextField
                name={FIELD_NAMES.COMMENT}
                label={`${LABELS.COMMENT}`}
                formikProps={{
                  field: formik.getFieldProps(FIELD_NAMES.COMMENT),
                  meta: formik.getFieldMeta(FIELD_NAMES.COMMENT),
                }}
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>
            <Grid size={12} className="actionButtonsWrapper">
              <ContainerGrid disableGutters={true}>
                <Grid size={12}>
                  <Box className="actionBtnWrap">
                    <Button
                      variant="contained"
                      color="error"
                      className="cmnBorderBtn yellow cancelBtn widthAuto cancelBtn"
                      onClick={() => setOpenConfirmationModal(true)}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="info" type="submit" className="cmnFillBtn widthAuto saveBtn">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </ContainerGrid>
            </Grid>
          </ContainerGrid>
        </Box>
      </form>
      <ConfirmationModal open={openConfirmationModal} onClose={handleCloseConfirmationModal} />
    </Box>
  );
};

export default CreateRequest;
