import { MouseEvent, useEffect, useState } from "react";
import { AppBar, Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS, USER_ROLES } from "../../utils/constants";
import { useAppSelector } from "../../hooks";
import Images from "../../assets/images";
import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";
import MobileMenuContent from "./components/MobileMenuContent";
import UserMenu from "./components/UserMenu";
import "./Header.scss";

declare global {
  interface Window {
    gigya: any;
  }
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state) => state.user);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRole, setCurrentRole] = useState<string>("");

  const container = window?.document.body ?? undefined;
  const isAuthPage = location.pathname.includes(ROUTE_PATHS.AUTH);

  const toggleMobileMenu = () => setIsMobileMenuOpen((prev) => !prev);

  const openUserMenu = (event: MouseEvent<HTMLElement>) => setUserMenuAnchorEl(event.currentTarget);
  const closeUserMenu = () => setUserMenuAnchorEl(null);

  const openDropdownMenu = (event: MouseEvent<HTMLButtonElement>) => setDropdownMenuAnchorEl(event.currentTarget);
  const closeDropdownMenu = () => setDropdownMenuAnchorEl(null);

  const isDropdownMenuOpen = Boolean(dropdownMenuAnchorEl);

  const handleLogout = () => {
    const logoutParams = {
      UID: userDetails.UID,
      callback: (response: any) => {
        if (response.errorCode === 0) {
          closeUserMenu();
          localStorage.removeItem(USER_ROLES.USER_DETAILS);
          navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
        }
      },
    };
    window.gigya.accounts.logout(logoutParams);
  };

  useEffect(() => {
    if (userDetails.role) setCurrentRole(userDetails.role);
  }, [userDetails]);

  return (
    <Box sx={{ display: "flex" }} className="headerComponentContainer" id="mainHeader">
      <CssBaseline />
      <AppBar component="nav" style={{ background: "#000000" }}>
        <Toolbar style={{ minHeight: 65 }}>
          {!isAuthPage && <MobileMenu toggleMobileMenu={toggleMobileMenu} />}
          <Box className="logoWrap" sx={{ display: "flex" }} component={NavLink} to="/">
            <img src={Images.HeaderLogo} alt="CUPS Logo" className="mainLogo" />
          </Box>
          {!isAuthPage && (
            <>
              <DesktopMenu
                currentRole={currentRole}
                isDropdownMenuOpen={isDropdownMenuOpen}
                dropdownMenuAnchorEl={dropdownMenuAnchorEl}
                openDropdownMenu={openDropdownMenu}
                closeDropdownMenu={closeDropdownMenu}
              />
              <UserMenu
                userDetails={userDetails}
                openUserMenu={openUserMenu}
                userMenuAnchorEl={userMenuAnchorEl}
                closeUserMenu={closeUserMenu}
                handleLogout={handleLogout}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={isMobileMenuOpen}
        onClose={toggleMobileMenu}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}>
        <MobileMenuContent toggleMobileMenu={toggleMobileMenu} currentRole={currentRole} handleLogout={handleLogout} />
      </Drawer>
    </Box>
  );
};

export default Header;
