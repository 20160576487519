export const LABELS = {
  NAME: "Name",
  STATE: "State",
  COUNTRY: "Country",
  CITY: "City",
  CUSTOMER: "Customer",
  CUSTOMER_NUMBER: "Customer Number",
  DISTRIBUTOR: "Distributor",
  DISTRIBUTOR_Nummber: "Distributor Number",
  OBSOLETE: "Obsolete",
  BUSINESS_ENTITY: "Business Entity",
  IS_EP: "Is EP",
  SOLD_NUMBER: "Sold-to Number",
  SALES_ORGANISATION: "Sales Organisation",
  CHOOSE: "Choose a",
  DISCOUNT: "Discount %",
  PRODUCT_SALES_TEXT: "Product Sales Text",
  COVESTRO_PRODUCT_NUMBER: "Covestro Product Number",
  SELECT_DISTRIBUTORS: "Select Distributors",
  MARKET_NAME: "Market Name",
  FULL_NAME: "Full Name",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  PASSWORD: "Password",
  CONNECTED_DISTRIBUTORS: "Connected Distributors",
  COMPETITOR_NAME: "Competitor Name",
  COVESTRO_SELLER: "Covestro Seller",
  LOCATION: "Location",
  OEM_NAME: "OEM Name",
  THIRD_PARTY_CONTRACT: "Third Party Contract",
  REQUESTED_BY: "Requested By",
  REQUESTED_DATE: "Requested Date",
  REQUESTED_ID: "Requested ID",
  UNIT_OF_MEASUREMENT: "Unit of measurement",
  APPLICATION: "Application",
  COMPETITOR_VOLUME_YEAR: "Competitor's Volume (YEAR)",
  COVESTRO_PRODUCT: "Covestro Product",
  DISTRIBUTOR_PRODUCT_NUMBER: "Distributor Product Number",
  EXPIRATION_DATE: "Expiration Date",
  EFFECTIVE_DATE: "Effective Date",
  MINIMUM_QTY_PURCHASE: "Minimum quantity to purchase",
  LIST_PRICE: "List price",
  REQUESTED: "Requested",
  ADJUSTMENT: "Adjustment",
  COMMENT: "Comment",
  INTO_STOCK_PRICE: "Into Stock Price",
  INTO_STOCK_DISCOUNT: "Into Stock discount",
  SUPPORTED_PRICE: "Supported price",
  SUPPORTED_DISCOUNT: "Supported discount",
  SUPPORT_PRICE: "Support Price",
  FILTER: "Filter",
  SELECT_EXISTING_Filter: "Select Existing Filter",
  NEW: "new",
  EXISTING: "existing",
  STATUS: "Status",
  VALID_TO: "Valid To",
  VALID_FROM: "Valid From",
  PRICE: "Price",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  // Form Type
  EDIT: "edit",
  CLONE: "clone",
  VIEW: "view",
  CREATE: "create",
};
