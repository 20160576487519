import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";

export interface Filter {
  column: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  user_type?: string;
  email?: string;
}

export interface DataTableState {
  isError: boolean;
  isRefetching: boolean;
  filters: Filter;
}

const initialState: DataTableState = {
  isError: false,
  isRefetching: false,
  filters: {
    column: [],
    sorting: [],
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  },
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Filter>) => {
      const { column, sorting, pagination } = action.payload;
      state.filters.column = column;
      state.filters.sorting = sorting;
      state.filters.pagination = pagination;
    },
    showLoader: (state) => {
      state.isRefetching = true;
    },
    hideLoader: (state) => {
      state.isRefetching = false;
    },
    resetOrAddFilters: (
      state,
      action: PayloadAction<{ column?: MRT_ColumnFiltersState; sorting?: MRT_SortingState }>
    ) => {
      const { column, sorting } = action.payload;
      state.filters.column = column || [];
      state.filters.sorting = sorting || [];
      state.filters.pagination = {
        pageIndex: 0,
        pageSize: 10,
      };
    },
  },
});

export const { setFilters, showLoader, hideLoader, resetOrAddFilters } = dataTableSlice.actions;

export default dataTableSlice.reducer;
